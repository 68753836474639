import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavDropdown from "./NavDropdown";

const NavItem = ({ children, route, isNested, nestedRoutes, onClick }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const navItemRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                navItemRef.current &&
                !navItemRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else if (isNested) {
            setIsOpen(!isOpen);
        } else if (route) {
            navigate(route);
        }
    };

    return (
        <li ref={navItemRef}>
            <div className="nav-item-container">
                <button className="nav-button" onClick={handleClick}>
                    {children}
                </button>
                {isNested && (
                    <NavDropdown
                        subMenu={nestedRoutes.map((route) => ({
                            label: route.label,
                            onClick: () => {
                                route.onClick();
                                setIsOpen(false);
                            },
                        }))}
                        isOpen={isOpen}
                        toggleSubNav={() => setIsOpen(!isOpen)}
                    />
                )}
            </div>
        </li>
    );
};

export default NavItem;
