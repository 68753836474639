import { useState, useEffect } from "react";
import serviceImg from "../../assets/service.png";
import { ModalComponent } from "../../components";
import {
    CheckCircleOutline as CheckCircleOutlineIcon,
    HighlightOff as HighlightOffIcon,
    AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
// import { CheckCircle2, XCircle, AlertCircle, Clock } from "lucide-react";
import "../../styles/LabManagerTabs/Orders.css";

const Orders = () => {
    const [activeTab, setActiveTab] = useState("incomingOrders");
    const [selectedStatus, setSelectedStatus] = useState("all");
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderModal, setOrderModal] = useState(false);

    const mockOrders = [
        {
            id: "1",
            title: "Professional Camera Setup",
            buyer: "Sarah Mitchell",
            assignee: "David Chen",
            price: 2499.99,
            quantity: 23,
            unit: "mg",
            category: "equipment-rental",
            status: "rejected",
            equipment: "Sony A7III Kit",
            description:
                "High-end camera rental for a 3-day commercial shoot. Includes primary and backup lenses, tripod, and lighting equipment.",
            createdAt: new Date("2025-01-28"),
        },
        {
            id: "1",
            title: "Professdfsional Camera Setup",
            buyer: "Sarah Mitchell",
            assignee: "David Chen",
            price: 2499.99,
            quantity: 23,
            unit: "mg",
            category: "equipment-rental",
            status: "pending",
            equipment: "Sony A7III Kit",
            description:
                "High-end camera rental for a 3-day commercial shoot. Includes primary and backup lenses, tripod, and lighting equipment.",
            createdAt: new Date("2025-01-28"),
        },
        {
            id: "1",
            title: "asdf Camera Setup",
            buyer: "Sarah Mitchell",
            assignee: "David Chen",
            price: 2499.99,
            quantity: 23,
            unit: "mg",
            category: "equipment-rental",
            status: "new",
            equipment: "Sony A7III Kit",
            description:
                "High-end camera rental for a 3-day commercial shoot. Includes primary and backup lenses, tripod, and lighting equipment.",
            createdAt: new Date("2025-01-28"),
        },
        {
            id: "1",
            title: "Professional Camera Setup",
            buyer: "Sarah Mitchell",
            assignee: "David Chen",
            price: 2499.99,
            quantity: 23,
            unit: "mg",
            category: "equipment-rental",
            status: "new",
            equipment: "Sony A7III Kit",
            description:
                "High-end camera rental for a 3-day commercial shoot. Includes primary and backup lenses, tripod, and lighting equipment.",
            createdAt: new Date("2025-01-28"),
        },
        {
            id: "1",
            title: "Professional Camera Setup",
            buyer: "Sarah Mitchell",
            assignee: "David Chen",
            price: 2499.99,
            quantity: 23,
            unit: "mg",
            category: "equipment-rental",
            status: "completed",
            equipment: "Sony A7III Kit",
            description:
                "High-end camera rental for a 3-day commercial shoot. Includes primary and backup lenses, tripod, and lighting equipment.",
            createdAt: new Date("2025-01-28"),
        },
        {
            id: "1",
            title: "Professional Camera Setup",
            buyer: "Sarah Mitchell",
            assignee: "David Chen",
            price: 2499.99,
            quantity: 23,
            unit: "mg",
            category: "equipment-rental",
            status: "new",
            equipment: "Sony A7III Kit",
            description:
                "High-end camera rental for a 3-day commercial shoot. Includes primary and backup lenses, tripod, and lighting equipment.",
            createdAt: new Date("2025-01-28"),
        },
        // ... other mock orders remain the same
    ];

    const filteredOrders = mockOrders.filter((order) => {
        if (selectedStatus !== "all" && order.status !== selectedStatus)
            return false;
        if (selectedCategory !== "all" && order.category !== selectedCategory)
            return false;
        return true;
    });

    const getStatusColor = (status) => {
        const statusColors = {
            new: "status-badge-new",
            pending: "status-badge-pending",
            completed: "status-badge-completed",
            rejected: "status-badge-rejected",
        };
        return statusColors[status] || "status-badge-default";
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case "approved":
                return (
                    <CheckCircleOutlineIcon className="status-icon approved" />
                );
            case "rejected":
                return <HighlightOffIcon className="status-icon rejected" />;
            default:
                return <AccessTimeIcon className="status-icon pending" />;
        }
    };

    useEffect(() => {
        console.log("Selected order changed:", selectedOrder);
        if (selectedOrder) {
            setOrderModal(true);
        } else {
            setOrderModal(false);
        }
    }, [selectedOrder]);

    return (
        <div className="lab-manager-orders-container">
            <ModalComponent
                title={selectedOrder ? selectedOrder.title : ""}
                isOpen={orderModal}
                toggle={() => setSelectedOrder(null)}
                onCancel={() => setSelectedOrder(null)}
                disabled={selectedOrder?.status !== "completed"}
                {...(selectedOrder?.status === "completed" && {
                    submitText: "Checkout",
                    onSubmit: () => console.log("trex"),
                })}
            >
                {selectedOrder && (
                    <>
                        {/* <div className="lab-manager-order-modal-header">
                            <div className="lab-manager-order-modal-metadata">
                                <h2>Price</h2>
                                <p
                                    className={`lab-manager-order-status-badge ${getStatusColor(
                                        selectedOrder.status
                                    )}`}
                                >
                                    {selectedOrder.status
                                        .charAt(0)
                                        .toUpperCase() +
                                        selectedOrder.status.slice(1)}
                                </p>
                            </div>
                            <div className="lab-manager-order-modal-metadata">
                                <h2>Status</h2>
                                <p
                                    className={`lab-manager-order-status-badge ${getStatusColor(
                                        selectedOrder.status
                                    )}`}
                                >
                                    {selectedOrder.status
                                        .charAt(0)
                                        .toUpperCase() +
                                        selectedOrder.status.slice(1)}
                                </p>
                            </div>
                            <div className="lab-manager-order-modal-metadata">
                                <h2>Status</h2>
                                <p
                                    className={`lab-manager-order-status-badge ${getStatusColor(
                                        selectedOrder.status
                                    )}`}
                                >
                                    {selectedOrder.status
                                        .charAt(0)
                                        .toUpperCase() +
                                        selectedOrder.status.slice(1)}
                                </p>
                            </div>
                            <br />
                        </div>
                        <div className="lab-manager-order-modal-approval-status">
                            <h2>Approval Status</h2>
                            <p>ads</p>
                        </div> */}
                        {/* Order Status */}
                        <div className="status-container">
                            <span className="label">Status</span>
                            <div
                                className={`status-badge ${getStatusColor(
                                    selectedOrder.status
                                )}`}
                            >
                                {selectedOrder.status.charAt(0).toUpperCase() +
                                    selectedOrder.status.slice(1)}
                            </div>
                        </div>

                        {/* Order Details */}
                        <div className="details-grid">
                            <div className="detail-item">
                                <span className="label">Price</span>
                                <p className="value">
                                    ${selectedOrder.price.toLocaleString()}
                                </p>
                            </div>
                            <div className="detail-item">
                                <span className="label">Quantity</span>
                                <p className="value">
                                    {selectedOrder.quantity}
                                </p>
                            </div>
                        </div>

                        {/* <Separator /> */}

                        {/* Approval Status Section */}
                        {selectedOrder.status === "pending" && (
                            <div className="approval-section">
                                <h3 className="section-title">
                                    Approval Status
                                </h3>

                                {/* Buyer Lab Manager Approval */}
                                <div className="approval-card">
                                    {getStatusIcon("pending")}
                                    <div className="approval-info">
                                        <p className="manager-name">
                                            {selectedOrder.buyerLab} Lab Manager
                                        </p>
                                        <p className="approval-status">
                                            Waiting for approval
                                        </p>
                                    </div>
                                </div>

                                {/* Seller Lab Manager Approval */}
                                <div className="approval-card">
                                    {getStatusIcon("pending")}
                                    <div className="approval-info">
                                        <p className="manager-name">
                                            {selectedOrder.sellerLab} Lab
                                            Manager
                                        </p>
                                        <p className="approval-status">
                                            Waiting for approval
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Rejection Information */}
                        {selectedOrder.status === "rejected" && (
                            <div className="rejection-section">
                                <h3 className="section-title">
                                    Rejection Details
                                </h3>
                                <div className="rejection-card">
                                    <HighlightOffIcon className="status-icon rejected" />
                                    <div className="rejection-info">
                                        <p className="manager-name">
                                            Rejected by Joshua Xu
                                        </p>
                                        <p className="rejection-reason">
                                            You suck.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Checkout Button for Approved Orders */}
                        {selectedOrder.status === "approved" && (
                            <div className="approved-section">
                                <div className="success-card">
                                    <CheckCircleOutlineIcon className="status-icon approved" />
                                    <p className="success-message">
                                        This order has been approved by both lab
                                        managers and is ready for checkout.
                                    </p>
                                </div>
                                <button className="checkout-button">
                                    Proceed to Checkout
                                </button>
                            </div>
                        )}
                    </>
                )}
            </ModalComponent>
            <div className="lab-manager-orders-tabs">
                <button
                    className={`lab-manager-orders-tab ${
                        activeTab === "internalOrders" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("internalOrders")}
                >
                    My Lab Orders
                </button>
                <button
                    className={`lab-manager-orders-tab ${
                        activeTab === "incomingOrders" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("incomingOrders")}
                >
                    Received Orders
                </button>
                <button
                    className={`lab-manager-orders-tab ${
                        activeTab === "outgoingOrders" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("outgoingOrders")}
                >
                    Placed Orders
                </button>
            </div>
            <div className="lab-manager-orders-header">
                <div>
                    <h1 className="lab-manager-orders-title">Placed Orders</h1>
                    <p className="lab-manager-orders-description">
                        Placed orders are orders that have{" "}
                        <span style={{ fontWeight: "600" }}>
                            been placed by other labs for your listings
                        </span>
                        .
                    </p>
                </div>
                <span className="lab-manager-orders-count">
                    {filteredOrders.length} Orders
                </span>
            </div>

            <div className="lab-manager-orders-filters-container">
                <div className="lab-manager-orders-filters-group">
                    <div className="lab-manager-orders-filter-section">
                        <h3 className="lab-manager-orders-filter-title">
                            Status
                        </h3>
                        <div className="lab-manager-orders-filter-options">
                            {["all", "new", "completed", "rejected"].map(
                                (status) => (
                                    <button
                                        key={status}
                                        onClick={() =>
                                            setSelectedStatus(status)
                                        }
                                        className={`lab-manager-orders-filter-button ${
                                            selectedStatus === status
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        {status.charAt(0).toUpperCase() +
                                            status.slice(1)}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                    <div className="lab-manager-orders-filter-section">
                        <h3 className="lab-manager-orders-filter-title">
                            Type
                        </h3>
                        <div className="lab-manager-orders-filter-options">
                            {[
                                "all",
                                "specific-item",
                                "equipment-rental",
                                "service",
                                "digital-good",
                            ].map((category) => (
                                <button
                                    key={category}
                                    onClick={() =>
                                        setSelectedCategory(category)
                                    }
                                    className={`lab-manager-orders-filter-button ${
                                        selectedCategory === category
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    {category
                                        .split("-")
                                        .map(
                                            (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                        )
                                        .join(" ")}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="lab-manager-orders-list">
                {filteredOrders.length === 0 ? (
                    <div className="lab-manager-no-orders">
                        No orders in this category
                    </div>
                ) : (
                    filteredOrders.map((order) => (
                        <div
                            key={order.id}
                            className="lab-manager-order-card"
                            onClick={() => setSelectedOrder(order)}
                        >
                            <div className="lab-manager-order-img">
                                <img
                                    src={serviceImg}
                                    alt={order.title}
                                    className="lab-manager-order-img"
                                />
                            </div>
                            <div className="lab-manager-order-content">
                                <div className="lab-manager-order-header">
                                    <div>
                                        <h3 className="lab-manager-order-title">
                                            {order.title}
                                        </h3>
                                        <p className="lab-manager-order-description">
                                            {order.description}
                                        </p>
                                    </div>
                                    <span
                                        className={`lab-manager-order-status-badge ${getStatusColor(
                                            order.status
                                        )}`}
                                    >
                                        {order.status.charAt(0).toUpperCase() +
                                            order.status.slice(1)}
                                    </span>
                                </div>
                                <div className="lab-manager-order-details">
                                    <div className="lab-manager-order-detail-item">
                                        <span className="lab-manager-order-detail-label">
                                            Buyer
                                        </span>
                                        <span className="lab-manager-order-detail-value">
                                            {order.buyer}
                                        </span>
                                    </div>
                                    <div className="lab-manager-order-detail-item">
                                        <span className="lab-manager-order-detail-label">
                                            Lab Member Assigned
                                        </span>
                                        <span className="lab-manager-order-detail-value">
                                            {order.assignee}
                                        </span>
                                    </div>
                                    <div className="lab-manager-order-detail-item">
                                        <span className="lab-manager-order-detail-label">
                                            Price
                                        </span>
                                        <span className="lab-manager-order-detail-value">
                                            $
                                            {order.price.toLocaleString(
                                                "en-US",
                                                {
                                                    minimumFractionDigits: 2,
                                                }
                                            )}
                                        </span>
                                    </div>
                                    <div className="lab-manager-order-detail-item">
                                        <span className="lab-manager-order-detail-label">
                                            Quantity
                                        </span>
                                        <span className="lab-manager-order-detail-value">
                                            {order.quantity} {order.unit}
                                        </span>
                                    </div>
                                    {/* {order.equipment && (
                                    <div className="lab-manager-order-detail-item">
                                        <span className="lab-manager-order-detail-label">
                                            Equipment
                                        </span>
                                        <span className="lab-manager-order-detail-value">
                                            {order.equipment}
                                        </span>
                                    </div>
                                )} */}
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Orders;

// ("use client");

// import { useState } from "react";
// import {
//     Dialog,
//     DialogContent,
//     DialogHeader,
//     DialogTitle,
// } from "@/components/ui/dialog";
// import { Button } from "@/components/ui/button";
// import { Badge } from "@/components/ui/badge";
// import { Separator } from "@/components/ui/separator";
// import { Clock, CheckCircle2, XCircle, AlertCircle } from "lucide-react";
// import "./OrderModal.css";

// export function OrderModal({ order, open, onOpenChange }) {
//     const [isCheckingOut, setIsCheckingOut] = useState(false);

//     const handleCheckout = async () => {
//         setIsCheckingOut(true);
//         // Simulate checkout process
//         await new Promise((resolve) => setTimeout(resolve, 1500));
//         setIsCheckingOut(false);
//         onOpenChange(false);
//     };

//     const getStatusColor = (status) => {
//         switch (status) {
//             case "approved":
//                 return "status-approved";
//             case "rejected":
//                 return "status-rejected";
//             default:
//                 return "status-pending";
//         }
//     };

//     return (
//         <Dialog open={open} onOpenChange={onOpenChange}>
//             <DialogContent className="modal-content">
//                 <DialogHeader>
//                     <DialogTitle className="modal-title">
//                         {order.title}
//                     </DialogTitle>
//                 </DialogHeader>

//                 <div className="modal-body">
//                     {/* Order Status */}
//                     <div className="status-container">
//                         <span className="label">Status</span>
//                         <Badge
//                             className={`status-badge ${getStatusColor(
//                                 order.status
//                             )}`}
//                         >
//                             {order.status.charAt(0).toUpperCase() +
//                                 order.status.slice(1)}
//                         </Badge>
//                     </div>

//                     {/* Order Details */}
//                     <div className="details-grid">
//                         <div className="detail-item">
//                             <span className="label">Price</span>
//                             <p className="value">
//                                 ${order.price.toLocaleString()}
//                             </p>
//                         </div>
//                         <div className="detail-item">
//                             <span className="label">Quantity</span>
//                             <p className="value">{order.quantity}</p>
//                         </div>
//                     </div>

//                     <Separator />

//                     {/* Approval Status Section */}
//                     {order.status === "pending" && (
//                         <div className="approval-section">
//                             <h3 className="section-title">Approval Status</h3>

//                             {/* Buyer Lab Manager Approval */}
//                             <div className="approval-card">
//                                 {getStatusIcon(order.approvals.buyer.status)}
//                                 <div className="approval-info">
//                                     <p className="manager-name">
//                                         {order.buyerLab} Lab Manager
//                                     </p>
//                                     <p className="approval-status">
//                                         {order.approvals.buyer.status ===
//                                         "pending"
//                                             ? "Waiting for approval"
//                                             : `Approved by ${order.approvals.buyer.labManager.name}`}
//                                     </p>
//                                 </div>
//                             </div>

//                             {/* Seller Lab Manager Approval */}
//                             <div className="approval-card">
//                                 {getStatusIcon(order.approvals.seller.status)}
//                                 <div className="approval-info">
//                                     <p className="manager-name">
//                                         {order.sellerLab} Lab Manager
//                                     </p>
//                                     <p className="approval-status">
//                                         {order.approvals.seller.status ===
//                                         "pending"
//                                             ? "Waiting for approval"
//                                             : `Approved by ${order.approvals.seller.labManager.name}`}
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {/* Rejection Information */}
//                     {order.status === "rejected" && (
//                         <div className="rejection-section">
//                             <h3 className="section-title">Rejection Details</h3>
//                             <div className="rejection-card">
//                                 <AlertCircle className="status-icon rejected" />
//                                 <div className="rejection-info">
//                                     <p className="manager-name">
//                                         Rejected by {order.rejectedBy?.name}
//                                     </p>
//                                     <p className="rejection-reason">
//                                         {order.rejectionReason}
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {/* Checkout Button for Approved Orders */}
//                     {order.status === "approved" && (
//                         <div className="approved-section">
//                             <div className="success-card">
//                                 <CheckCircle2 className="status-icon approved" />
//                                 <p className="success-message">
//                                     This order has been approved by both lab
//                                     managers and is ready for checkout.
//                                 </p>
//                             </div>
//                             <Button
//                                 className="checkout-button"
//                                 onClick={handleCheckout}
//                                 disabled={isCheckingOut}
//                             >
//                                 {isCheckingOut
//                                     ? "Processing..."
//                                     : "Proceed to Checkout"}
//                             </Button>
//                         </div>
//                     )}
//                 </div>
//             </DialogContent>
//         </Dialog>
//     );
// }
