import { doc, getDoc } from "@firebase/firestore";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BiotechIcon from "@mui/icons-material/Biotech";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import HubIcon from "@mui/icons-material/Hub";
import LoginIcon from "@mui/icons-material/Login";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SettingsIcon from "@mui/icons-material/Settings";
import Badge from "@mui/material/Badge";
import Toolbar from "@mui/material/Toolbar";
import { getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LabGiantLogo from "../../assets/labgiantlogo.png";
import { UserAuth } from "../../context/auth-context";
import { useNotifications } from "../../context/notifications-context";
import { db, storage } from "../../firebase-config";
import "../../styles/Navigation.css";
import NavItem from "./NavItem";
import Search from "./Search";

const Navigation = () => {
    let navigate = useNavigate();

    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [lab, setLab] = useState(null);

    const { user, logout } = UserAuth();
    const { notifications, isNotificationsOpen, setIsNotificationsOpen } =
        useNotifications();

    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            console.log(error.message);
        }
    };

    const [isSubNavOpen, setIsSubNavOpen] = useState({
        profile: false,
        browse: false,
    });

    const toggleNav = () => {
        setMobileNavOpen(!mobileNavOpen);
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            toggleNav();
        }
    };

    // prevent scroll when nav menu open
    useEffect(() => {
        if (mobileNavOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [mobileNavOpen]);

    useEffect(() => {
        const loadLab = async () => {
            if (user && user.uid) {
                const member = await getDoc(doc(db, "Users", user.uid));
                if (member.data().labId) {
                    const lab = await getDoc(
                        doc(db, "Labs", member.data().labId)
                    );
                    const labLogoRef = ref(
                        storage,
                        `/labs/${lab.id}/images/logo`
                    );
                    const url = await getDownloadURL(labLogoRef);

                    setLab({ id: lab.id, ...lab.data(), logoURL: url });
                    sessionStorage.setItem("labId", JSON.stringify(lab.id));
                } else {
                    setLab(null);
                }
            } else {
                setLab(null);
            }
        };
        loadLab();
    }, [user]);

    // Add this useEffect to handle clicks outside notifications
    useEffect(() => {
        const handleClickOutside = (event) => {
            const notificationsButton = event.target.closest(
                ".notifications-button"
            );
            const notificationsDropdown = event.target.closest(
                ".notifications-dropdown"
            );
            const notificationsList = document.querySelector(
                ".notifications-list-container"
            );

            if (
                !notificationsButton &&
                !notificationsDropdown &&
                !notificationsList?.contains(event.target) &&
                isNotificationsOpen
            ) {
                setIsNotificationsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, [isNotificationsOpen]);

    return (
        <Toolbar
            className="mainNav"
            sx={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
            <div className="logoSearchContainer">
                <div className="logo" onClick={() => navigate("/newsfeed")}>
                    <img
                        src={LabGiantLogo}
                        alt="Lab Logo"
                        className="lab-logo"
                    />
                </div>
                <Search />
                <div
                    className="hamburgerMenu"
                    tabIndex="0"
                    onClick={toggleNav}
                    onKeyDown={handleKeyPress}
                >
                    <span className="sr-only">Open mobile navigation menu</span>
                    <span
                        className={`top ${mobileNavOpen ? "topClose" : ""}`}
                    ></span>
                    <span
                        className={`mid ${mobileNavOpen ? "midClose" : ""}`}
                    ></span>
                    <span
                        className={`bottom ${
                            mobileNavOpen ? "bottomClose" : ""
                        }`}
                    ></span>
                </div>
            </div>
            <nav
                className={`navLinksContainer ${mobileNavOpen ? "active" : ""}`}
                onClick={() => {
                    if (mobileNavOpen) {
                        toggleNav();
                    }
                }}
            >
                <ul className="nav-item-list">
                    <NavItem route="/newsfeed">
                        <DynamicFeedIcon className="nav-icon" />
                        Giant Board
                    </NavItem>
                    <NavItem route="/listings">
                        <BiotechIcon className="nav-icon" />
                        Technologies
                    </NavItem>
                    <NavItem route="/labs">
                        <HubIcon className="nav-icon" />
                        Labs
                    </NavItem>
                    {!lab && user && (
                        <NavItem route="/labsignup">
                            <RocketLaunchIcon className="nav-icon" />
                            Register Lab
                        </NavItem>
                    )}
                    {lab && (
                        <NavItem route={`/manager/${lab.id}`}>
                            <ManageAccountsIcon className="nav-icon" />
                            Manager
                        </NavItem>
                    )}
                    {lab && (
                        <NavItem route={`/lab/${lab.id}`}>
                            <div className="nav-icon-lab">
                                <img src={lab.logoURL} alt="Lab Logo" />
                            </div>
                            My Lab
                        </NavItem>
                    )}

                    {user ? (
                        <>
                            {/* Desktop profile dropdown */}
                            <div className="desktop-only">
                                <NavItem
                                    isNested={true}
                                    nestedRoutes={[
                                        {
                                            label: "Profile",
                                            onClick: () =>
                                                navigate(
                                                    `/profile/${user.uid}`
                                                ),
                                        },
                                        {
                                            label: "Account Settings",
                                            onClick: () =>
                                                navigate(`/account-settings`),
                                        },

                                        {
                                            label: "Logout",
                                            onClick: () => handleLogout(),
                                        },
                                    ]}
                                >
                                    <PersonIcon className="nav-icon" />
                                    <span className="nav-item-label">
                                        Profile
                                        <ArrowDropDownIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                    </span>
                                </NavItem>
                            </div>

                            {/* Mobile profile link */}
                            <div className="mobile-only">
                                <NavItem route={`/profile/${user.uid}`}>
                                    <PersonIcon className="nav-icon" />
                                    Profile
                                </NavItem>
                            </div>

                            {/* Notifications for both views */}
                            <li>
                                <div className="nav-item-container">
                                    <button
                                        className="nav-button notifications-button"
                                        color="inherit"
                                        onClick={() =>
                                            setIsNotificationsOpen(
                                                !isNotificationsOpen
                                            )
                                        }
                                    >
                                        {notifications.filter(
                                            (notification) =>
                                                !notification.isRead
                                        ).length > 0 ? (
                                            <>
                                                <Badge
                                                    badgeContent={
                                                        notifications.filter(
                                                            (notification) =>
                                                                !notification.isRead
                                                        ).length
                                                    }
                                                    color="primary"
                                                    sx={{
                                                        "& .MuiBadge-badge": {
                                                            fontSize: "10px",
                                                            height: "14px",
                                                            minWidth: "14px",
                                                            width: "14px",
                                                        },
                                                    }}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                >
                                                    <NotificationsIcon className="nav-icon" />
                                                </Badge>
                                                Notifications
                                            </>
                                        ) : (
                                            <>
                                                <NotificationsIcon className="nav-icon" />
                                                Notifications
                                            </>
                                        )}
                                    </button>
                                </div>
                            </li>

                            {/* Move Account Settings here, after notifications */}
                            <div className="mobile-only">
                                <NavItem
                                    onClick={() =>
                                        navigate("/account-settings")
                                    }
                                >
                                    <SettingsIcon className="nav-icon" />
                                    Account Settings
                                </NavItem>
                            </div>

                            {/* Mobile logout at the end */}
                            <div className="mobile-only">
                                <NavItem onClick={handleLogout}>
                                    <LoginIcon className="nav-icon" />
                                    Logout
                                </NavItem>
                            </div>
                        </>
                    ) : (
                        <>
                            <NavItem route="/?mode=login">
                                <button className="nav-button get-started">
                                    <LoginIcon className="nav-icon" />
                                    Get Started
                                </button>
                            </NavItem>
                        </>
                    )}

                    {user && user.isAdmin ? (
                        <NavItem route="/admin">
                            <AdminPanelSettingsIcon className="nav-icon" />
                            Admin
                        </NavItem>
                    ) : null}
                </ul>
            </nav>
        </Toolbar>
    );
};

export default Navigation;
