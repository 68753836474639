import { useState, useEffect } from "react";
import { fetchGet, fetchPost } from "../../../services/data-service";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/Admin/Admin.css";

const AdminLabTransfer = () => {
    const { labId } = useParams();
    const [lab, setLab] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsersWithNullLabId = async () => {
            const users = await fetchGet("/admin/users/no-lab", true);
            setUsers(users);
            console.log("users", users);
        };
        const fetchLab = async () => {
            const lab = await fetchGet(`/admin/lab/${labId}`, true);
            setLab(lab);
        };
        fetchLab();
        fetchUsersWithNullLabId();
    }, []);

    const filteredUsers =
        searchQuery && users
            ? users.filter(
                  (user) =>
                      user.firstName
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                      user.lastName
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                      user.email
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
              )
            : [users];

    const handleTransfer = () => {
        setIsLoading(true);
        // Simulate API call

        const transferLab = async () => {
            // await fetchPost(
            //     `/admin/lab/${labId}/transfer/${selectedUser.id}`,
            //     {}
            // );
            console.log("trsnader");
        };

        transferLab();

        setTimeout(() => {
            setIsLoading(false);
            setShowConfirmation(false);
            setSelectedUser(null);
            setSearchQuery("");
            alert("Lab successfully transferred!");
        }, 1500);
    };

    return (
        <div className="admin-lab-transfer">
            <div className="admin-lab-transfer-header">
                <h1>Transfer Lab Ownership</h1>
                <h2>{lab?.name}</h2>
                <p>
                    Transfer an existing lab profile to a Principal Investigator
                </p>
            </div>

            <div className="admin-lab-transfer-form">
                <div className="admin-lab-transfer-search-box">
                    <label htmlFor="admin-lab-transfer-user-search">
                        Search for Principal Investigator
                    </label>
                    <div className="admin-lab-transfer-search-input-wrapper">
                        <input
                            id="admin-lab-transfer-user-search"
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search by name or email..."
                            autoComplete="off"
                        />
                        {searchQuery && (
                            <button
                                className="admin-lab-transfer-clear-search"
                                onClick={() => setSearchQuery("")}
                                aria-label="Clear search"
                            >
                                ×
                            </button>
                        )}
                    </div>
                </div>

                {searchQuery && (
                    <div className="admin-lab-transfer-search-results">
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((user) => (
                                <button
                                    key={user.id}
                                    className={`admin-lab-transfer-user-item ${
                                        selectedUser?.id === user.id
                                            ? "selected"
                                            : ""
                                    }`}
                                    onClick={() => setSelectedUser(user)}
                                >
                                    <div className="admin-lab-transfer-user-item-content">
                                        <div className="admin-lab-transfer-user-info-primary">
                                            <span className="admin-lab-transfer-user-name">
                                                {user.firstName} {user.lastName}
                                            </span>
                                            <span className="admin-lab-transfer-user-role">
                                                {user.institutionName} -{" "}
                                                {user.departmentName}
                                            </span>
                                        </div>
                                        <span className="admin-lab-transfer-user-email">
                                            {user.email}
                                        </span>
                                    </div>
                                </button>
                            ))
                        ) : (
                            <div className="admin-lab-transfer-no-results">
                                No Principal Investigators found
                            </div>
                        )}
                    </div>
                )}

                {selectedUser && (
                    <div className="admin-lab-transfer-selected-user">
                        <div className="admin-lab-transfer-selected-user-info">
                            <div className="admin-lab-transfer-selected-user-details">
                                <h3
                                    onClick={() =>
                                        navigate(`/profile/${selectedUser.id}`)
                                    }
                                >
                                    {selectedUser.firstName}{" "}
                                    {selectedUser.lastName}
                                </h3>
                                <p>{selectedUser.email}</p>
                                <p>
                                    {selectedUser.institutionName} -{" "}
                                    {selectedUser.departmentName}
                                </p>
                            </div>
                        </div>
                        <button
                            className="admin-lab-transfer-button"
                            onClick={() => setShowConfirmation(true)}
                        >
                            Transfer Lab Access
                        </button>
                    </div>
                )}
            </div>

            {showConfirmation && (
                <div className="admin-lab-transfer-confirmation-modal">
                    <div className="admin-lab-transfer-confirmation-modal-content">
                        <h2>Confirm Transfer</h2>
                        <p>
                            Are you sure you want to transfer the lab to{" "}
                            <strong>{selectedUser.name}</strong>?
                            <br />
                            This action cannot be undone.
                        </p>
                        <div className="admin-lab-transfer-confirmation-modal-actions">
                            <button
                                className="admin-lab-transfer-confirmation-modal-cancel-button"
                                onClick={() => setShowConfirmation(false)}
                                disabled={isLoading}
                            >
                                Cancel
                            </button>
                            <button
                                className="admin-lab-transfer-confirmation-modal-confirm-button"
                                onClick={handleTransfer}
                                disabled={isLoading}
                            >
                                {isLoading
                                    ? "Transferring..."
                                    : "Confirm Transfer"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default AdminLabTransfer;

// import React from "react";

// const AdminLabTransfer = () => {
//     /**
//      *
//      * How to transfer a lab to a current user?
//      *
//      * Requirements
//      * - User must have a valid Users document
//      * - User must have a NULL labId in the Users document
//      *
//      *
//      * Retrieving Users Steps
//      * 1. Retrieve all Users with a NULL labId in the Users document.
//      * 2. Choose the user to transfer the lab to.
//      *
//      *
//      * Transferring Lab to User Steps
//      * 1. Update the labId in the Users document to the labId of the lab to be transferred.
//      * 2. Find the current piId of the lab, find the member document of that piId and delete it.
//      * 3. Add the user document to the members collection of the Lab document and update the piId field to the userId of the user to be transferred.
//      * 4. For each listings of that lab, update the labMemberInCharge field to the userId of the user to be transferred.
//      * 5. Change the status of the lab to be transferred to "approved" if not already.
//      * 6. Change the temporary field to false.
//      *
//      */

//     return <div>Transfer Lab to a current user.</div>;
// };

// export default AdminLabTransfer;
