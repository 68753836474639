import { useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import { fetchDelete, fetchPost } from "../services/data-service";
import {
    fetchLab,
    fetchLabMembers,
    fetchLabFollowers,
    fetchLabPublications,
    fetchLabRequests,
    LabCacheParams,
} from "../services/lab-api";
import { useNavigate } from "react-router-dom";
import {
    LabProfileContext,
    LabProfileContextProvider,
} from "../context/lab-profile-context";
import { useParams, useLocation } from "react-router-dom";
import { UserAuth } from "../context/auth-context";
import { PublicationDetails } from "./LabProfileTabs";
import { postNotification } from "../firebase/crud";
import { Loading, ModalComponent } from "../components";
import {
    AccountBalance as AccountBalanceIcon,
    Add as AddIcon,
    Check as CheckIcon,
    Share as ShareIcon,
    School as SchoolIcon,
    ImportContacts as ImportContactsIcon,
    FileCopy as FileCopyIcon,
    Shortcut as ShortcutIcon,
} from "@mui/icons-material";
import QRCode from "qrcode";
import "../styles/LabProfile.css";

const LabProfileContainer = () => {
    const [activeTab, setActiveTab] = useState("overview");
    const navigate = useNavigate();
    const { labId } = useParams();
    const { user, loading: userLoading } = UserAuth();
    const [unfollowModalOpen, setUnfollowModalOpen] = useState(false);
    const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [linkCopied, setLinkCopied] = useState(false);
    const qrCodeLink = `${window.location.origin}/lab/${labId}`;
    const {
        publicationModalOpen,
        setPublicationModalOpen,
        selectedPublication,
    } = useContext(LabProfileContext);

    const location = useLocation();
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        if (!qrCodeModalOpen) {
            setLinkCopied(false);
        }
    }, [qrCodeModalOpen]);

    const tabs = [
        {
            name: "Lab Overview",
            id: "overview",
        },
        {
            name: "Technologies & Resources",
            id: "listings",
        },
        {
            name: "News",
            id: "news",
        },
        {
            name: "Publications",
            id: "publications",
        },
        {
            name: "Members",
            id: "members",
        },
    ];

    const queryClient = useQueryClient();

    const { data: lab, isLoading: labLoading } = useQuery({
        queryKey: ["lab", labId, { banner: true }],
        queryFn: fetchLab,
        ...LabCacheParams,
    });

    const { data: members, isLoading: membersLoading } = useQuery({
        queryKey: [
            "lab",
            labId,
            "members",
            { groupDescription: true, personalDescription: true },
        ],
        queryFn: fetchLabMembers,
        ...LabCacheParams,
    });

    const { data: publications, isLoading: publicationsLoading } = useQuery({
        queryKey: ["lab", labId, "publications"],
        queryFn: fetchLabPublications,
        ...LabCacheParams,
    });

    const { data: followers, isLoading: followersLoading } = useQuery({
        queryKey: ["lab", labId, "followers"],
        queryFn: fetchLabFollowers,
        ...LabCacheParams,
    });

    const { data: requests, isLoading: requestsLoading } = useQuery({
        queryKey: ["lab", labId, "requests", { status: "pending" }],
        queryFn: fetchLabRequests,
        ...LabCacheParams,
    });

    const hasRequested = useMemo(() => {
        if (requestsLoading || !user) return false;
        return requests.some((request) => request.userId === user.uid);
    }, [requests, user]);

    const isFollowing = useMemo(() => {
        if (followersLoading || !user) return false;
        return followers.some((follower) => follower.userId === user.uid);
    }, [followers, user]);

    const filteredTabs = useMemo(() => {
        if (!lab) return tabs;
        return lab?.temporary
            ? tabs.filter((tab) => tab.id !== "members")
            : tabs;
    }, [lab]);

    useEffect(() => {
        const currentTab = location.pathname.split("/")[3];
        setActiveTab(currentTab);

        // Only scroll if there's a "fromTab" state in the location
        if (location.state?.fromTab && window.innerWidth <= 1300) {
            const bannerHeight =
                document.querySelector(".lab-profile-banner")?.offsetHeight ||
                250;
            window.scrollTo({
                top: bannerHeight,
                behavior: "instant",
            });

            const observer = new MutationObserver((mutations) => {
                const updatedBannerHeight =
                    document.querySelector(".lab-profile-banner")
                        ?.offsetHeight || 250;
                window.scrollTo({
                    top: updatedBannerHeight,
                    behavior: "instant",
                });
            });

            const contentContainer = document.querySelector(
                ".lab-profile-content-container"
            );
            if (contentContainer) {
                observer.observe(contentContainer, {
                    childList: true,
                    subtree: true,
                    attributes: true,
                });
            }

            return () => observer.disconnect();
        }
    }, [location]);

    const unfollowLab = async () => {
        await fetchDelete(`/labs/${labId}/followers/${user.uid}`, true);
    };

    const followLab = async () => {
        const data = {
            userData: {
                userId: user.uid,
                firstName: user.firstName,
                lastName: user.lastName,
            },
            labData: {
                labId: labId,
                labName: lab.name,
            },
        };
        await fetchPost(`/labs/${labId}/followers`, data, true);
    };

    const requestJoin = async (lab) => {
        try {
            const labRequest = await fetchPost(
                `/labs/${lab.id}/requests`,
                {
                    userId: user.uid,
                    labId: lab.id,
                    labName: lab.name,
                    status: "pending",
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    createdAt: new Date(),
                },
                true
            );

            await fetchPost(
                `/users/${user.uid}/labRequests`,
                {
                    labRequestId: labRequest.id,
                    labId: lab.id,
                    labName: lab.name,
                    status: "pending",
                    createdAt: new Date(),
                },
                true
            );
        } catch {
            console.error(`Failed to send join request to lab: ${lab.id}`);
        }

        postNotification({
            userId: lab.piId,
            title: "New Lab Join Request!",
            content: `${user.firstName} ${user.lastName} has requested to join your lab ${lab.name}`,
            url: `/manager/${lab.id}/members`,
        });

        alert("Lab Join Request Sent!");
    };

    const handleTabClick = (tabId) => {
        navigate(`/lab/${labId}/${tabId}`, { state: { fromTab: true } });
    };

    const { mutate: followLabMutation } = useMutation({
        mutationFn: () => followLab(),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["lab", labId, "followers"],
            });
        },
        onError: () => {
            console.error("Failed to follow lab");
        },
    });

    const { mutate: unfollowLabMutation } = useMutation({
        mutationFn: () => unfollowLab(),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["lab", labId, "followers"],
            });
            setUnfollowModalOpen(false);
        },
        onError: () => {
            console.error("Failed to unfollow lab");
            setUnfollowModalOpen(false);
        },
    });

    if (
        labLoading ||
        membersLoading ||
        publicationsLoading ||
        followersLoading ||
        requestsLoading
    ) {
        return <Loading />;
    }

    return (
        <div key={labId} className="lab-profile-container">
            <ModalComponent
                isOpen={publicationModalOpen}
                toggle={() => setPublicationModalOpen(false)}
            >
                {selectedPublication && (
                    <PublicationDetails publication={selectedPublication} />
                )}
            </ModalComponent>
            <ModalComponent
                title="Unfollow Lab"
                isOpen={unfollowModalOpen}
                toggle={() => setUnfollowModalOpen(!unfollowModalOpen)}
                submitText="Unfollow Lab"
                onSubmit={unfollowLabMutation}
            >
                Are you sure you want to unfollow this lab?
            </ModalComponent>
            <ModalComponent
                title="Lab QR Code"
                isOpen={qrCodeModalOpen}
                toggle={() => setQrCodeModalOpen(!qrCodeModalOpen)}
                submitText={
                    linkCopied ? (
                        <CheckIcon style={{ fontSize: "16px" }} />
                    ) : (
                        <FileCopyIcon style={{ fontSize: "16px" }} />
                    )
                }
                onSubmit={() => {
                    if (!linkCopied) {
                        navigator.clipboard.writeText(qrCodeLink);
                        setLinkCopied(true);
                    } else {
                        setLinkCopied(false);
                    }
                }}
            >
                <div className="lab-profile-qr-code-container">
                    <img
                        style={{
                            width: "200px",
                            height: "200px",
                            border: "2px solid black",
                            borderRadius: "10px",
                        }}
                        src={qrCode}
                        alt="Lab QR Code"
                    />
                    <div className="lab-profile-qr-code-input-container">
                        <label htmlFor="qr-code-input">Copy Link</label>
                        <input
                            style={{ width: "100%" }}
                            type="text"
                            className="input"
                            value={qrCodeLink}
                        />
                    </div>
                </div>
            </ModalComponent>
            <div className="lab-profile">
                <div className="lab-profile-banner">
                    <div className="lab-profile-banner-ellipses">
                        <div className="lab-profile-banner-ellipse-1" />
                        <div className="lab-profile-banner-ellipse-2" />
                    </div>
                    <div
                        className="lab-profile-image-banner"
                        style={
                            lab.banner && {
                                backgroundImage: `url(${lab.banner})`,
                            }
                        }
                    />
                </div>
                <div className="lab-profile-centered-content">
                    <div className="lab-profile-logo">
                        <img src={lab.logo} alt="Lab Logo" />
                    </div>
                    <div className="lab-profile-banner-info">
                        <div className="lab-profile-banner-info-badges">
                            <div>
                                <AccountBalanceIcon
                                    style={{ fontSize: "12px" }}
                                />
                                {lab.institutionName}
                            </div>
                            <div>
                                <SchoolIcon style={{ fontSize: "12px" }} />
                                {lab.facultyName}
                            </div>
                            <div>
                                <ImportContactsIcon
                                    style={{ fontSize: "12px" }}
                                />
                                {lab.departmentName}
                            </div>
                        </div>
                        <h1>{lab.name}</h1>
                        <h2>{lab.keywords.join(", ")}</h2>
                    </div>
                    <div className="lab-profile-banner-metrics">
                        {[
                            {
                                value: followers.length,
                                name: "Collaborators",
                            },
                            {
                                value: members.members.length,
                                name: "Members",
                                onClick: () => handleTabClick("members"),
                            },
                            {
                                value: publications.length,
                                name: "Publications",
                                onClick: () => handleTabClick("publications"),
                            },
                        ].map((metric) => (
                            <div
                                className="lab-profile-banner-metric"
                                key={metric.name}
                                onClick={metric.onClick}
                                style={
                                    metric.onClick ? { cursor: "pointer" } : {}
                                }
                            >
                                <h2>{metric.value}</h2>
                                <h3>{metric.name}</h3>
                            </div>
                        ))}
                    </div>

                    <div className="lab-profile-banner-buttons">
                        <button
                            className="lab-profile-banner-button"
                            onClick={() => setQrCodeModalOpen(true)}
                        >
                            <ShareIcon style={{ fontSize: "16px" }} />
                            Share
                        </button>

                        {user &&
                            (isFollowing ? (
                                <button
                                    className="lab-profile-banner-button"
                                    onClick={() => {
                                        setUnfollowModalOpen(true);
                                    }}
                                >
                                    <CheckIcon style={{ fontSize: "16px" }} />
                                    Followed
                                </button>
                            ) : (
                                <button
                                    className="lab-profile-banner-button"
                                    onClick={followLabMutation}
                                >
                                    <AddIcon style={{ fontSize: "16px" }} />
                                    Follow Lab
                                </button>
                            ))}
                        {user && !user?.labId && (
                            <button
                                className="lab-profile-banner-button"
                                onClick={() => requestJoin(lab)}
                                disabled={lab.hasRequested}
                            >
                                {hasRequested ? (
                                    <>
                                        <CheckIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                        Requested
                                    </>
                                ) : (
                                    <>
                                        <ShortcutIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                        Request To Join
                                    </>
                                )}
                            </button>
                        )}
                    </div>
                    <ul className="lab-profile-nav">
                        {filteredTabs.map((tab) => (
                            <li
                                key={tab.id}
                                className={activeTab === tab.id ? "active" : ""}
                                onClick={() => handleTabClick(tab.id)}
                            >
                                {tab.name}
                            </li>
                        ))}
                    </ul>
                    <div className="lab-profile-content-container">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

const LabProfile = () => {
    return (
        <LabProfileContextProvider>
            <LabProfileContainer />
        </LabProfileContextProvider>
    );
};

export default LabProfile;
