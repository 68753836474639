import { useState, useEffect, useRef } from "react";
import {
    Groups as GroupsIcon,
    AccessTime as AccessTimeIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    GridView as GridViewIcon,
    List as ListIcon,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { TypeBadge } from "../helpers/ListingHelpers";
import { Loading } from "../components";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchListings, ListingCacheParams } from "../services/listing-api";
import { CircularProgress } from "@mui/material";
import "../styles/BrowseListings.css";
import "../styles/LabProfileTabs/LabListings.css";

const TimePeriodDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState("30 days");
    const dropdownRef = useRef(null);

    const timePeriods = [
        "30 days",
        "60 days",
        "90 days",
        "6 months",
        "1 year",
        "All time",
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (timePeriod) => {
        setSelectedTimePeriod(timePeriod);
        setIsOpen(false);
    };

    return (
        <div ref={dropdownRef} className="time-period-dropdown">
            <button onClick={toggleDropdown} className="time-period-button">
                <AccessTimeIcon />
                {selectedTimePeriod}
            </button>
            {isOpen && (
                <ul className="time-period-list">
                    {timePeriods.map((timePeriod) => (
                        <li
                            key={timePeriod}
                            onClick={() => handleSelect(timePeriod)}
                            className="time-period-item"
                        >
                            {timePeriod}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const Facets = ({ isOpen }) => {
    const [closedFacets, setClosedFacets] = useState({});

    const facets = [
        {
            title: "Type",
            options: [
                "All",
                "Specific Item",
                "Equipment Rental",
                "Service",
                "Digital Good",
            ],
        },
        {
            title: "Price Range",
            options: ["All", "$0 - $50", "$50 - $100", "$100+"],
        },
    ];

    const toggleFacet = (index) => {
        setClosedFacets((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    return (
        <div className="filter-container">
            {isOpen && (
                <div className="filter-dropdown">
                    {facets.map((facet, index) => (
                        <div key={index} className="facet">
                            <div
                                className="facet-title"
                                onClick={() => toggleFacet(index)}
                            >
                                {facet.title}
                                <div className="facet-title-arrow">
                                    {!closedFacets[index] ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </div>
                            </div>
                            {!closedFacets[index] && (
                                <div className="facet-options">
                                    <ul>
                                        {facet.options.map(
                                            (option, optionIndex) => (
                                                <li
                                                    key={optionIndex}
                                                    className="facet-option"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={option}
                                                        name={option}
                                                        value={option}
                                                        className="facet-option-checkbox"
                                                    />
                                                    <label
                                                        htmlFor={option}
                                                        className="facet-option-label"
                                                    >
                                                        {option}
                                                    </label>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const BrowseListings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("query");
    const { ref, inView } = useInView();

    const {
        data: listings,
        isLoading: listingsLoading,
        fetchNextPage: fetchNextPaginatedListings,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ["listings", searchQuery],
        queryFn: ({ pageParam }) =>
            fetchListings({
                pageParam,
                query: searchQuery,
            }),
        ...ListingCacheParams,
        getNextPageParam: (lastPage) =>
            lastPage.hasMore ? lastPage.lastVisible : null,
    });
    const [viewMode, setViewMode] = useState("grid");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [previousViewMode, setPreviousViewMode] = useState(null);

    // Update resize listener
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);

            if (window.innerWidth <= 765) {
                // Store current view mode before switching to grid
                if (viewMode === "list") {
                    setPreviousViewMode("list");
                }
                setViewMode("grid");
            } else if (window.innerWidth > 765 && previousViewMode === "list") {
                // Restore list view when screen becomes wide enough
                setViewMode("list");
                setPreviousViewMode(null);
            }
        };

        window.addEventListener("resize", handleResize);

        // Initial check
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [viewMode, previousViewMode]); // Add dependencies

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                fetchNextPaginatedListings();
            }, 500);
        }
    }, [inView]);

    const renderListings = () => {
        if (viewMode === "grid") {
            return (
                <div className="lab-listings-grid">
                    {listings.pages.map((page) =>
                        page.documents.map((listing, index) => (
                            <div
                                key={listing.id}
                                className="lab-listings-listing"
                                onClick={() => {
                                    if (
                                        listing.type === "equipment-rental" ||
                                        listing.type === "training-expertise"
                                    ) {
                                        navigate(
                                            `/listingrental/${listing.id}`
                                        );
                                    } else {
                                        navigate(`/listing/${listing.id}`);
                                    }
                                }}
                            >
                                <img
                                    src={listing.img}
                                    className="lab-listings-listing-img"
                                    alt=""
                                />
                                <div className="lab-listings-listing-info">
                                    <div className="lab-listings-listing-name">
                                        {listing.title}
                                    </div>
                                    {listing.type === "digital-good" ? (
                                        <div className="lab-listings-listing-price">
                                            Free access
                                        </div>
                                    ) : listing.price.value === 0 ? (
                                        <div className="lab-listings-listing-price">
                                            Free access
                                        </div>
                                    ) : listing.price.value >= 9999 ? (
                                        <div className="lab-listings-listing-price">
                                            Contact for pricing
                                        </div>
                                    ) : (
                                        <div className="lab-listings-listing-price">
                                            <span style={{ fontWeight: 600 }}>
                                                ${listing.price.value}
                                            </span>{" "}
                                            <span style={{ fontSize: "16px" }}>
                                                per{" "}
                                                {listing.price.customUnits
                                                    ? listing.price.customUnits
                                                    : listing.price.units}
                                            </span>
                                        </div>
                                    )}
                                    <div className="lab-listings-listing-description">
                                        {listing.description}
                                    </div>
                                    <div className="lab-listings-listing-footer">
                                        <div className="lab-listings-listing-type">
                                            <TypeBadge
                                                type={listing.type}
                                                style={{ fontSize: "16px" }}
                                            />
                                        </div>
                                        <div className="lab-listings-listing-lister">
                                            <GroupsIcon
                                                style={{ fontSize: "16px" }}
                                            />
                                            <span className="lab-listings-listing-lister-name">
                                                {listing.labName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            );
        }

        return (
            <div className="lab-listings-list">
                {listings.pages.map((page) =>
                    page.documents.map((listing, index) => (
                        <div
                            key={listing.id}
                            className="lab-listings-list-item"
                            onClick={() => {
                                if (
                                    listing.type === "equipment-rental" ||
                                    listing.type === "training-expertise"
                                ) {
                                    navigate(`/listingrental/${listing.id}`);
                                } else {
                                    navigate(`/listing/${listing.id}`);
                                }
                            }}
                        >
                            <img
                                src={listing.img}
                                className="lab-listings-listing-img"
                                alt=""
                            />
                            <div className="lab-listings-listing-info">
                                <div className="lab-listings-listing-header">
                                    <div className="lab-listings-listing-name">
                                        {listing.title}
                                    </div>
                                    {listing.type === "digital-good" ? (
                                        <div className="lab-listings-listing-price">
                                            Free access
                                        </div>
                                    ) : listing.price.value === 0 ? (
                                        <div className="lab-listings-listing-price">
                                            Free access
                                        </div>
                                    ) : listing.price.value >= 9999 ? (
                                        <div className="lab-listings-listing-price">
                                            Contact for pricing
                                        </div>
                                    ) : (
                                        <div className="lab-listings-listing-price">
                                            <span style={{ fontWeight: 600 }}>
                                                ${listing.price.value}
                                            </span>{" "}
                                            <span style={{ fontSize: "16px" }}>
                                                per{" "}
                                                {listing.price.customUnits
                                                    ? listing.price.customUnits
                                                    : listing.price.units}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="lab-listings-listing-description">
                                    {listing.description}
                                </div>
                                <div className="lab-listings-listing-footer">
                                    <div className="lab-listings-listing-type">
                                        <TypeBadge
                                            type={listing.type}
                                            style={{ fontSize: "16px" }}
                                        />
                                    </div>
                                    <div className="lab-listings-listing-lister">
                                        <GroupsIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                        <span className="lab-listings-listing-lister-name">
                                            {listing.labName}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        );
    };

    if (listingsLoading) {
        return <Loading />;
    }

    return (
        <div className="browse-listings-container">
            <div className="browse-listings-container-content">
                {/* <div className="lab-listings-filter">
                <button
                    onClick={toggleFacets}
                    className="lab-listings-filter-facets"
                >
                    <FilterListIcon />
                    Filter
                </button>
                <TimePeriodDropdown />
            </div> */}
                {/* <div
                style={{ height: "370px" }}
                className="lab-listings-search-banner"
            >
                <div className="lab-profile-banner-ellipses">
                    <div className="lab-profile-banner-ellipse-1" />
                    <div className="lab-profile-banner-ellipse-2" />
                </div>
                <div className="lab-profile-search-inputs">
                    <h2 className="lab-profile-search-title">
                        Discover the latest technologies, services, and
                        resources <br /> across labs and institutions.
                    </h2>
                    {searchQueries.map((_, idx) => (
                        <div
                            key={idx}
                            className="lab-profile-search-inputs-container"
                        >
                            <select className="lab-profile-search-input">
                                <option value="All">All fields</option>
                                <option value="School">School</option>
                                <option value="Department">Department</option>
                                <option value="Faculty">Faculty</option>
                                <option value="Lab">Lab</option>
                            </select>
                            <input
                                className="lab-profile-search-input"
                                type="text"
                                placeholder="Search"
                            />
                            {idx > 0 && (
                                <button
                                    onClick={() => {
                                        setSearchQueries((prev) =>
                                            prev.filter((_, i) => i !== idx)
                                        );
                                    }}
                                >
                                    <RemoveIcon style={{ fontSize: "14px" }} />
                                </button>
                            )}
                        </div>
                    ))}
                    <div className="lab-profile-search-buttons">
                        <button
                            onClick={() => {
                                if (searchQueries.length < 3) {
                                    setSearchQueries((prev) => [
                                        ...prev,
                                        { field: "all", query: "" },
                                    ]);
                                }
                            }}
                        >
                            <AddIcon style={{ fontSize: "14px" }} />
                            Add search field
                        </button>
                        <button>
                            <SearchIcon style={{ fontSize: "14px" }} />
                            Search
                        </button>
                    </div>
                </div>
            </div> */}
                <div className="lab-listings-content">
                    {searchQuery && (
                        <div className="browse-listings-header">
                            <h2>
                                Search results for{" "}
                                <span style={{ fontWeight: 600 }}>
                                    "{searchQuery}"
                                </span>
                            </h2>
                        </div>
                    )}
                    {/* <Facets isOpen={isOpen} /> */}
                    <div className="view-toggle">
                        <button
                            className={`view-toggle-button ${
                                viewMode === "grid" ? "active" : ""
                            }`}
                            onClick={() => setViewMode("grid")}
                        >
                            <GridViewIcon style={{ marginRight: "4px" }} /> Grid
                        </button>
                        <button
                            className={`view-toggle-button ${
                                viewMode === "list" ? "active" : ""
                            }`}
                            onClick={() =>
                                windowWidth > 765 && setViewMode("list")
                            }
                        >
                            <ListIcon style={{ marginRight: "4px" }} /> List
                        </button>
                    </div>
                    <div className="lab-listings-content">
                        {renderListings()}
                    </div>
                </div>
            </div>
            <div ref={ref} className="browse-listings-pagination">
                {isFetchingNextPage && (
                    <div className="browse-listings-pagination-loading">
                        <CircularProgress
                            size={24}
                            style={{
                                color: "var(--primary-color)",
                                border: "10px",
                            }}
                        />
                        <span>Loading listings...</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BrowseListings;
