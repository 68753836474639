import React from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessages } from "../helpers/ListingHelpers";
import "../../src/styles/CreateListingStyles.css";

export const ListingField = ({
    field,
    title,
    description,
    required,
    multiline,
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="create-listing-field">
            <h3>
                {title}
                {required && <span style={{ color: "red" }}>*</span>}
            </h3>
            <h4>{description}</h4>
            {multiline ? (
                <textarea
                    className={
                        errors?.fields?.[field] ? "input input-error" : "input"
                    }
                    {...register(`fields.${field}`, { required })}
                    style={{
                        height: "90px",
                        resize: "vertical",
                        fontFamily: "inherit",
                        padding: "8px",
                    }}
                />
            ) : (
                <input
                    className={
                        errors?.fields?.[field] ? "input input-error" : "input"
                    }
                    type="text"
                    {...register(`fields.${field}`, { required })}
                />
            )}
            {errors?.fields?.[field] && (
                <ErrorMessages message="The above field is required." />
            )}
        </div>
    );
};

export default ListingField;
