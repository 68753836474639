import { updateDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase-config";
import { postNotification } from "../../firebase/crud";
import { fetchGet, fetchPut } from "../../services/data-service";
import "../../styles/Admin/Admin.css";

const AdminLabRegistrations = () => {
    const [labRegistrations, setLabRegistrations] = useState([]);

    const fetchLabRegistrations = async () => {
        const labRegistrationsData = await fetchGet(
            "/labs?status=pending",
            true
        );
        setLabRegistrations(labRegistrationsData);
    };
    useEffect(() => {
        fetchLabRegistrations();
    }, []);

    const approveLab = async (labRegistration) => {
        await fetchPut(`/labs/${labRegistration.id}`, {
            status: "approved",
        });
        fetchLabRegistrations();
    };

    const rejectLab = async (labRegistration) => {
        await fetchPut(`/labs/${labRegistration.id}`, {
            status: "rejected",
        });
        await updateDoc(doc(db, "Users", labRegistration.piId), {
            labId: "",
        });
        postNotification({
            userId: labRegistration.piId,
            title: "Lab Registration Rejected",
            content: "Your lab registration has been rejected.",
            url: "",
        });
        fetchLabRegistrations();
    };

    const LabRegistrationComponent = ({ labRegistration }) => {
        return (
            <div
                key={labRegistration.id}
                className="lab-registration-component"
            >
                <div className="lab-registration-info">
                    <div>
                        <span style={{ fontWeight: 700 }}>Lab Name: </span>
                        {labRegistration.name}
                    </div>
                    <div
                        style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 5,
                        }}
                    >
                        <span style={{ fontWeight: 700 }}>Overview: </span>
                        {labRegistration.overview[0]?.content}
                    </div>
                    <div>
                        <span style={{ fontWeight: 700 }}>PI Email: </span>
                        {labRegistration.email}
                    </div>
                </div>
                <div className="lab-registration-tags">
                    <span>{labRegistration.institutionName}</span>
                    <span>{labRegistration.facultyName}</span>
                    <span>{labRegistration.departmentName}</span>
                </div>
                <div className="lab-registration-actions">
                    {labRegistration.status === "pending" && (
                        <>
                            <button onClick={() => approveLab(labRegistration)}>
                                Approve
                            </button>
                            <button onClick={() => rejectLab(labRegistration)}>
                                Reject
                            </button>
                        </>
                    )}
                    {labRegistration.status === "approved" && (
                        <div>Approved</div>
                    )}
                    {labRegistration.status === "rejected" && (
                        <div>Rejected</div>
                    )}
                </div>
            </div>
        );
    };

    const LabRegistrationList = ({ labRegistrations }) => {
        return (
            <div className="lab-registration-list">
                <h1 style={{ textAlign: "center", fontSize: "24px" }}>
                    Lab Registrations
                </h1>
                {labRegistrations.map((labRegistration) => (
                    <div
                        key={labRegistration.id}
                        className="lab-registration-container"
                    >
                        <LabRegistrationComponent
                            labRegistration={labRegistration}
                        />
                    </div>
                ))}
            </div>
        );
    };

    return <LabRegistrationList labRegistrations={labRegistrations} />;
};

export default AdminLabRegistrations;
