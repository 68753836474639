import { fetchGet } from "./data-service";

const FETCH_LIMIT = 18;

export const ListingCacheParams = {
    staleTime: 20 * 1000 * 60,
    cacheTime: 30 * 1000 * 60,
};

export const fetchListings = async ({
    pageParam: lastVisible = null,
    query = null,
}) => {
    try {
        let queryParams = new URLSearchParams();
        queryParams.append("limit", FETCH_LIMIT);

        if (lastVisible) {
            queryParams.append("lastVisible", lastVisible);
        }

        if (query) {
            queryParams.append("query", query);
        }

        const res = await fetchGet(`/listings?${queryParams.toString()}`);

        for (const listing of res.documents) {
            const { name } = await fetchGet(`/labs/${listing.labId}`, false);
            listing.labName = name;
        }

        return res;
    } catch (error) {
        console.error("Error fetching listings:", error);
        throw error;
    }
};

export const fetchListing = async ({ queryKey }) => {
    try {
        const [_, listingId] = queryKey;
        const res = await fetchGet(`/listings/${listingId}`);
        return res;
    } catch (err) {
        console.error("Error fetching listing:", err);
        throw err;
    }
};
