import React, { useEffect, useState } from "react";
import { fetchGet } from "../../services/data-service";
import { useNavigate, Outlet } from "react-router-dom";
import { ArrowRight as ArrowRightIcon } from "@mui/icons-material";
import "../../styles/Admin/Admin.css";

const AdminLabs = () => {
    const [labs, setLabs] = useState([]);
    const [activeLab, setActiveLab] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLabs = async () => {
            const labs = await fetchGet("/admin/labs", true);
            const labsObj = {};
            labs.forEach((lab) => {
                if (!labsObj[lab.departmentName]) {
                    labsObj[lab.departmentName] = {
                        isOpen: true,
                        labs: [],
                    };
                }
                labsObj[lab.departmentName].labs.push(lab);
            });
            setLabs(labsObj);
        };
        fetchLabs();
    }, []);

    return (
        <div className="admin-labs">
            <div className="admin-labs-container">
                {Object.entries(labs).map(([departmentName, lab], idx) => (
                    <div key={idx} className="admin-labs-department">
                        <h2>
                            {departmentName} ({lab.labs.length})
                            <ArrowRightIcon
                                style={{
                                    fontSize: "20px",
                                    transform: lab.isOpen
                                        ? "rotate(90deg)"
                                        : "none",
                                    transition: "transform 0.3s ease",
                                }}
                                onClick={() => {
                                    setLabs((prev) => ({
                                        ...prev,
                                        [departmentName]: {
                                            ...prev[departmentName],
                                            isOpen: !prev[departmentName]
                                                .isOpen,
                                        },
                                    }));
                                }}
                                className="admin-labs-department-arrow"
                            />
                        </h2>
                        {lab.isOpen &&
                            lab.labs.map((lab, idx) => (
                                <div
                                    key={idx}
                                    className="admin-labs-component"
                                    onClick={() => {
                                        navigate(`/admin/labs/${lab.id}`);
                                        setActiveLab(lab.id);
                                    }}
                                >
                                    <h2>{lab.name}</h2>
                                </div>
                            ))}
                    </div>
                ))}
            </div>
            <Outlet />
        </div>
    );
};

export default AdminLabs;
