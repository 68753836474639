import { useState } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { fetchDelete } from "../../services/data-service";
import { ModalComponent } from "../../components";

const AdminLabManager = () => {
    const { labId } = useParams();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const navigate = useNavigate();
    const tabs = [
        {
            name: "Overview",
            path: "overview",
        },
        {
            name: "Listings",
            path: "listings",
        },
        {
            name: "News",
            path: "news",
        },
        {
            name: "Lab Transfer",
            path: "lab-transfer",
        },
    ];

    const deleteLab = () => {
        fetchDelete(`/admin/lab/${labId}`, true).then(() => {
            window.location.href = "/admin/labs";
        });
    };

    return (
        <div className="admin-lab-manager-container">
            <div className="admin-lab-manager-tabs">
                {tabs.map((tab) => (
                    <div
                        key={tab.path}
                        className="admin-lab-manager-tab"
                        onClick={() =>
                            navigate(`/admin/labs/${labId}/${tab.path}`)
                        }
                    >
                        {tab.name}
                    </div>
                ))}
                <div
                    className="admin-lab-manager-tab"
                    onClick={() => setIsDeleteModalOpen(true)}
                >
                    Delete Lab
                </div>
            </div>
            <div className="admin-lab-manager-content">
                <Outlet />
            </div>
            <ModalComponent
                title="Confirm Delete Lab"
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                submitText="Confirm"
                onSubmit={() => {
                    deleteLab();
                    setIsDeleteModalOpen(false);
                }}
            >
                Are you sure you want to delete this lab?
            </ModalComponent>
        </div>
    );
};

export default AdminLabManager;
