import { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db, storage } from "../firebase-config";
import { ref, deleteObject, uploadBytes } from "firebase/storage";
import {
    collection,
    doc,
    getDoc,
    Timestamp,
    updateDoc,
} from "firebase/firestore";
import { Loading } from "../components";
import {
    EquipmentRental,
    SpecificItem,
    Service,
    DigitalGood,
} from "./ListingCategories";
import { fetchGet } from "../services/data-service";

const ListingCategories = ({ listing, labMembers }) => {
    if (!listing) {
        return <Loading />;
    }
    if (listing.type === "equipment-rental") {
        return <EquipmentRental labMembers={labMembers} listing={listing} />;
    } else if (listing.type === "specific-item") {
        return <SpecificItem labMembers={labMembers} listing={listing} />;
    } else if (listing.type === "service") {
        return <Service labMembers={labMembers} listing={listing} />;
    } else if (listing.type === "digital-good") {
        return <DigitalGood labMembers={labMembers} listing={listing} />;
    }
};

const EditListing = () => {
    const { listingId } = useParams();
    const [listing, setListing] = useState(null);
    const [labMembers, setLabMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const methods = useForm({
        shouldUnregister: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const listing = await fetchGet(`/listings/${listingId}`, true);
                const members = await fetchGet(
                    `/labs/${listing.labId}/members`,
                    true
                );

                const membersData = members.members.map((member) => {
                    const memberName = member.firstName + " " + member.lastName;
                    return {
                        value: memberName,
                        label: memberName,
                        id: member.id,
                    };
                });
                setLabMembers(membersData);
                setListing(listing);

                methods.reset({
                    fields: {
                        title: listing.title,
                        description: listing.description,
                        labMember: listing.labMember,
                        licensingType: {
                            value: listing.licensingType,
                            label: listing.licensingType,
                        },
                        retrievalMethods: listing.retrievalMethods,
                        relevantLink: listing.relevantLink,
                        isCollaborative: listing.isCollaborative,
                        imgs: [
                            ...(listing.imgs || []),
                            ...Array(3 - (listing.imgs?.length || 0)).fill({}),
                        ],
                    },
                });
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [listingId]);

    if (isLoading) {
        return <Loading />;
    }

    const handleCancel = () => {
        const currentPath = location.pathname;
        const newPath = currentPath.split("/").slice(0, -2).join("/");
        navigate(newPath);
    };

    const handleSave = () => {
        const uploadImage = (file, id) => {
            const imageId = doc(collection(db, "Listings")).id;
            const imageString = `listings/${id}/images/${imageId}`;
            const imageRef = ref(storage, imageString);
            uploadBytes(imageRef, file).then(() => {
                console.log("Image Uploaded");
            });

            return { ref: imageString };
        };

        const deleteImage = (path) => {
            const imageRef = ref(storage, path);
            deleteObject(imageRef).then(() => {
                console.log("Image Deleted");
            });
        };

        const saveData = async () => {
            if (await methods.trigger()) {
                const { dirtyFields } = methods.formState;
                if (Object.keys(dirtyFields).length !== 0) {
                    const listingRef = doc(db, "Listings", listingId);
                    const listingDoc = await getDoc(listingRef);
                    const listingData = listingDoc.data();
                    let updatedListing = {};
                    Object.keys(dirtyFields.fields).forEach((key) => {
                        if (key === "licensingType") {
                            updatedListing[key] = methods.getValues(
                                `fields.${key}.value`
                            );
                        } else if (key === "imgs") {
                            const imageRefsArray = listingData.imgRefs || [];
                            methods
                                .getValues("fields.imgs")
                                .forEach((img, index) => {
                                    if (img.shouldDelete && img.file) {
                                        const imgRef =
                                            listingData.imgRefs[index];
                                        deleteImage(imgRef.ref);
                                        imageRefsArray[index] = uploadImage(
                                            img.file,
                                            listingId
                                        );
                                    } else if (img.file) {
                                        imageRefsArray.push(
                                            uploadImage(img.file, listingId)
                                        );
                                    } else if (img.shouldDelete) {
                                        const imgRef =
                                            listingData.imgRefs[index];
                                        deleteImage(imgRef.ref);
                                        imageRefsArray.splice(index, 1);
                                    }
                                });

                            updatedListing.imgRefs = imageRefsArray;
                        } else if (key === "retrievalMethods") {
                            updatedListing[key] = methods.getValues(
                                `fields.${key}`
                            );
                        } else {
                            updatedListing[key] = methods.getValues(
                                `fields.${key}`
                            );
                        }
                    });
                    updatedListing.updatedAt = Timestamp.now();
                    console.log(updatedListing);
                    await updateDoc(listingRef, updatedListing);
                }
                const currentPath = location.pathname;
                const newPath = currentPath.split("/").slice(0, -2).join("/");
                navigate(newPath);
            } else {
                console.log("errors", methods.formState.errors);
            }
        };
        saveData().catch((error) => {
            console.error("Error saving listing: ", error);
        });
    };

    return (
        <FormProvider {...methods}>
            <form>
                <ListingCategories listing={listing} labMembers={labMembers} />
                <div className="edit-listing-footer">
                    <button type="button" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button type="button" onClick={handleSave}>
                        Save Changes
                    </button>
                </div>
            </form>
        </FormProvider>
    );
};

export default EditListing;
