import { Tab, Tabs } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../../styles/Admin/Admin.css";

const Admin = () => {
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const tabNames = [
        { label: "Lab Registrations", path: "/admin/registrations" },
        { label: "Admin Labs", path: "/admin/labs" },
        { label: "Create Admin Lab", path: "/admin/create-lab" },
    ];

    useEffect(() => {
        setActiveTab(
            tabNames.findIndex((tab) => tab.path === window.location.pathname)
        );
    }, []);

    const handleTabChange = (_, newValue) => {
        setActiveTab(newValue);
        navigate(tabNames[newValue].path);
    };

    return (
        <div className="admin-container">
            <div className="admin-tabs-container">
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    sx={{
                        "& .MuiTab-root": {
                            textTransform: "none",
                            fontSize: "16px",
                        },
                    }}
                >
                    {tabNames.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                    ))}
                </Tabs>
            </div>
            <Outlet />
        </div>
    );
};

export default Admin;
