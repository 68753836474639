import { fetchGet } from "./data-service";

export const LabCacheParams = {
    staleTime: 10 * 1000 * 60,
    cacheTime: 15 * 1000 * 60,
};

export const fetchLabs = async ({ queryKey }) => {
    const [_, { status, page = 1, limit = 24 } = {}] = queryKey;
    try {
        const res = await fetchGet(
            `/labs?status=${status}&page=${page}&limit=${limit}`
        );
        for (const lab of res) {
            if (!lab.temporary) {
                const pi = await fetchGet(`/users/${lab.piId}`, false);
                lab.piName = `${pi.firstName} ${pi.lastName}`;
            } else {
                const members = await fetchGet(
                    `/labs/${lab.id}/members`,
                    false
                );
                const pi = members.members[0];
                lab.piName = `${pi.firstName} ${pi.lastName}`;
            }
        }
        return res;
    } catch (error) {
        console.error("Error fetching labs:", error);
        throw error;
    }
};
export const fetchLab = async ({ queryKey }) => {
    const [_, labId, { banner = false } = {}] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}?banner=${banner}`);
        return res;
    } catch (error) {
        console.error("Error fetching lab:", error);
        throw error;
    }
};
export const fetchLabMembers = async ({ queryKey }) => {
    const [
        _,
        labId,
        { groupDescription = false, personalDescription = false } = {},
    ] = queryKey;
    try {
        console.log(groupDescription, personalDescription);
        const res = await fetchGet(
            `/labs/${labId}/members?groupDescription=${groupDescription}&personalDescription=${personalDescription}`
        );
        return res;
    } catch (error) {
        console.error("Error fetching lab members:", error);
        throw error;
    }
};

export const fetchLabMember = async ({ queryKey }) => {
    try {
        const [_, labId, , userId] = queryKey;
        const res = await fetchGet(`/labs/${labId}/members/${userId}`);
        return res;
    } catch (error) {
        console.error("Error fetching lab member:", error);
        throw error;
    }
};

export const fetchLabFollowers = async ({ queryKey }) => {
    const [_, labId] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}/followers`);
        return res;
    } catch (error) {
        console.error("Error fetching lab followers:", error);
        throw error;
    }
};
export const fetchLabPublications = async ({ queryKey }) => {
    const [_, labId] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}/publications`);
        return res;
    } catch (error) {
        console.error("Error fetching lab publications:", error);
        throw error;
    }
};

export const fetchLabRequests = async ({ queryKey }) => {
    const [_, labId, { status }] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}/requests?status=${status}`);
        return res;
    } catch (error) {
        console.error("Error fetching lab requests:", error);
        throw error;
    }
};

export const fetchLabListings = async ({ queryKey }) => {
    const [_, labId] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}/listings`, false);
        return res;
    } catch (error) {
        console.error("Error fetching listings:", error);
        throw error;
    }
};

export const fetchLabNews = async ({ queryKey }) => {
    const [_, labId] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}/news`, true);
        return res;
    } catch (error) {
        console.error("Error fetching news:", error);
        throw error;
    }
};
