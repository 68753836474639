import { useEffect, useState, useRef } from "react";
import {
    AttachMoney as AttachMoneyIcon,
    BarChart as BarChartIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Book as BookIcon,
    Email as EmailIcon,
    Group as GroupIcon,
    Inventory as InventoryIcon,
    Assignment as AssignmentIcon,
    Settings as SettingsIcon,
    Biotech as BiotechIcon,
    Newspaper as NewspaperIcon,
    ArrowRight as ArrowRightIcon,
    MenuBook as MenuBookIcon,
} from "@mui/icons-material";
import { useNavigate, useParams, useLocation, Outlet } from "react-router-dom";
import { UserAuth } from "../context/auth-context";
import { Loading } from "../components";
import { roles } from "../constants/LabConstants";
import { useQuery } from "@tanstack/react-query";
import { fetchLab, fetchLabMember, LabCacheParams } from "../services/lab-api";
import "../styles/Manager.css";

const Manager = () => {
    const { tab, labId } = useParams();
    const navigate = useNavigate();
    const { user } = UserAuth();
    const location = useLocation();
    const subTabContainerRef = useRef(null);
    const [activeTab, setActiveTab] = useState(tab || "overview");
    const [isCollapsed, setIsCollapsed] = useState(false);

    const { data: lab, isLoading: labLoading } = useQuery({
        queryKey: ["lab", labId, { banner: false }],
        queryFn: fetchLab,
        enabled: !!labId,
        ...LabCacheParams,
    });

    const { data: labMember, isLoading: labMemberLoading } = useQuery({
        queryKey: ["lab", labId, "members", user.uid],
        queryFn: fetchLabMember,
        enabled: !!labId && !!user.uid,
        ...LabCacheParams,
    });

    const tabs = {
        overview: {
            name: "Lab Profile",
            Icon: BookIcon,
            path: "overview",
            access: ["pi"],
            requiresLabApproval: false,
        },
        news: {
            name: "News",
            Icon: NewspaperIcon,
            path: "news",
            access: ["pi"],
            requiresLabApproval: true,
        },

        members: {
            name: "Members",
            Icon: GroupIcon,
            path: "members",
            access: ["pi"],
            requiresLabApproval: true,
        },
        listings: {
            name: "Tech & Resources",
            Icon: BiotechIcon,
            path: "listings",
            access: ["pi", "manager", "member"],
            requiresLabApproval: true,
        },
        publications: {
            name: "Publications",
            Icon: MenuBookIcon,
            path: "publications",
            access: ["pi"],
            requiresLabApproval: true,
        },
        messages: {
            name: "Messages",
            Icon: EmailIcon,
            path: "messages",
            access: ["pi", "manager", "member"],
            requiresLabApproval: true,
        },

        orders: {
            name: "Orders",
            Icon: AssignmentIcon,
            path: "orders",
            access: ["pi", "manager", "member"],
            requiresLabApproval: true,
        },

        // orders: {
        //     name: "Orders",
        //     Icon: AssignmentIcon,
        //     path: "orders",
        //     isOpen: false,
        //     access: ["pi", "manager", "member"],
        //     subTabs: {
        //         incomingOrders: {
        //             name: "Incoming Orders",
        //             path: "incoming-orders",
        //         },
        //         myOrders: {
        //             name: "My Orders",
        //             path: "my-orders",
        //         },
        //         myLabOrders: {
        //             name: "My Lab Orders",
        //             path: "mylab-orders",
        //         },
        //     },
        //     requiresLabApproval: true,
        // },
        inventory: {
            name: "Inventory",
            Icon: InventoryIcon,
            path: "inventory",
            access: ["pi", "manager", "member"],
            requiresLabApproval: true,
        },
        statistics: {
            name: "Statistics",
            Icon: BarChartIcon,
            path: "statistics",
            access: ["pi"],
            requiresLabApproval: true,
        },
        finances: {
            name: "Finances",
            Icon: AttachMoneyIcon,
            path: "finances",
            access: ["pi"],
            requiresLabApproval: true,
        },
        settings: {
            name: "Settings",
            Icon: SettingsIcon,
            path: "settings",
            access: ["pi"],
            requiresLabApproval: false,
        },
    };

    const [tabState, setTabState] = useState(tabs);

    useEffect(() => {
        const subroutes = location.pathname.split("/");
        const thirdSubroute = subroutes[3];
        const key = Object.keys(tabs).find(
            (key) =>
                tabs[key].path === thirdSubroute ||
                (tabs[key].subTabs &&
                    Object.values(tabs[key].subTabs).some(
                        (subTab) => subTab.path === thirdSubroute
                    ))
        );
        setActiveTab(key);
    }, [location]);

    useEffect(() => {
        // Set initial collapsed state based on screen width
        const handleResize = () => {
            setIsCollapsed(window.innerWidth <= 768);
        };

        // Set initial state
        handleResize();

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleTabClick = (tab, subTab) => {
        /** If the tab has subtabs, then we neet to toggle the isOpen property. */
        if (subTab) {
            navigate(`/manager/${lab.id}/${tabs[tab].subTabs[subTab].path}`);
        } else if (tabs[tab].subTabs) {
            setTabState((prev) => ({
                ...prev,
                [tab]: {
                    ...prev[tab],
                    isOpen: !prev[tab].isOpen,
                },
            }));
        } else {
            setActiveTab(tab);
            navigate(`/manager/${lab.id}/${tabs[tab].path}`);
        }
    };

    const handleCollapseClick = () => {
        setIsCollapsed(!isCollapsed);
    };

    if (labLoading || labMemberLoading) {
        return <Loading />;
    }

    return (
        <div className="vertical-tab-container">
            <div
                className={`vertical-tab-menu ${
                    isCollapsed ? "collapsed" : ""
                }`}
            >
                <div className="vertical-tab-menu-header">
                    <h1
                        style={{
                            display: isCollapsed ? "none" : "",
                            fontWeight: 600,
                        }}
                    >
                        {roles[labMember.role || "member"]}
                    </h1>
                    <h2
                        style={{ display: isCollapsed ? "none" : "" }}
                        onClick={() => navigate(`/lab/${lab.id}`)}
                    >
                        {lab.name}
                    </h2>
                </div>
                <div className="vertical-tab-menu-items">
                    {Object.entries(tabState)
                        .filter(
                            ([_, { requiresLabApproval }]) =>
                                !requiresLabApproval ||
                                lab.status === "approved"
                        )
                        .filter(([_, { access }]) =>
                            access.find((a) => labMember.role === a)
                        )

                        .map(([key, val]) => (
                            <div
                                key={key}
                                className="vertical-tab-menu-item-container"
                            >
                                <div
                                    className={`vertical-tab-menu-item ${
                                        activeTab === key ? "active" : ""
                                    }`}
                                    onClick={() => handleTabClick(key)}
                                >
                                    <val.Icon className="tab-icon" />
                                    <span
                                        style={{
                                            display: isCollapsed
                                                ? "none"
                                                : "inline-flex",
                                        }}
                                    >
                                        {val.name}
                                        {val.subTabs && !isCollapsed && (
                                            <ArrowRightIcon
                                                style={{
                                                    fontSize: "20px",
                                                    transform: val.isOpen
                                                        ? "rotate(90deg)"
                                                        : "none",
                                                    transition:
                                                        "transform 0.3s ease",
                                                }}
                                                className="sub-tab-arrow"
                                            />
                                        )}
                                    </span>
                                </div>
                                {val?.isOpen && (
                                    <div
                                        className={`${
                                            isCollapsed
                                                ? "sub-tabs-collapsed"
                                                : "sub-tabs"
                                        }`}
                                        ref={subTabContainerRef}
                                    >
                                        <ul className="sub-tab-list">
                                            {Object.entries(val.subTabs).map(
                                                ([subKey, { name }]) => (
                                                    <li
                                                        key={subKey}
                                                        className="sub-tab-item"
                                                        onClick={() =>
                                                            handleTabClick(
                                                                key,
                                                                subKey
                                                            )
                                                        }
                                                    >
                                                        <span>{name}</span>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
                <div className="vertical-tab-user-section">
                    <div
                        className="vertical-tab-user-info"
                        onClick={() => navigate(`/profile/${user.uid}`)}
                    >
                        <div className="user-and-arrow">
                            {user.firstName} {user.lastName}
                        </div>
                    </div>
                    <div
                        className="vertical-tab-collapse"
                        onClick={handleCollapseClick}
                    >
                        {isCollapsed ? (
                            <ChevronRightIcon className="collapse-icon" />
                        ) : (
                            <ChevronLeftIcon className="collapse-icon" />
                        )}
                    </div>
                </div>
            </div>
            <div className="vertical-tab-content">
                <Outlet />
            </div>
        </div>
    );
};

export default Manager;
