import React, { useState, useEffect } from "react";
import { fetchGet } from "../../services/data-service";
import { useParams } from "react-router-dom";
import { marked } from "marked";

const ResearchOverview = () => {
    const { labId } = useParams();
    const [overview, setOverview] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchGet(`/labs/${labId}?overview=true`);
            setOverview(data.sections);
        };

        fetchData();
    }, [labId]);

    const renderContent = (content) => {
        // Set marked options to match OverviewManager
        marked.setOptions({
            gfm: true,
            breaks: true,
            pedantic: false,
            sanitize: false,
            smartLists: true,
            smartypants: false,
        });

        // Clean up the content
        let cleanContent = content
            .replace(/\\\s/g, " ")
            .replace(/\\([^n])/g, "$1")
            .trim();

        // Convert markdown to HTML
        let html = marked(cleanContent);

        // Add target="_blank" and rel="noopener noreferrer" to links
        html = html.replace(/<a href="([^"]+)">/g, (match, url) => {
            // Ensure URL is absolute
            const absoluteUrl =
                url.startsWith("http://") || url.startsWith("https://")
                    ? url
                    : `https://${url}`;
            return `<a href="${absoluteUrl}" target="_blank" rel="noopener noreferrer">`;
        });

        return { __html: html };
    };

    return (
        <div className="lab-profile-content">
            {overview.map((section, index) => (
                <div
                    key={index}
                    className="lab-profile-content-overview-section-wrapper"
                >
                    <h3>{section.title}</h3>
                    <div
                        className="markdown-content"
                        dangerouslySetInnerHTML={renderContent(section.content)}
                    />
                    {section.files.length > 0 && (
                        <div className="overview-section-images">
                            {section.files.map((file) => (
                                <div
                                    key={file.url}
                                    className="overview-section-images-container"
                                >
                                    <img src={file.url} alt={file.name} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ResearchOverview;
