import React, { useState, useEffect } from "react";
import ModalComponent from "../../components/ModalComponent";
import {
    CreditCard as CreditCardIcon,
    Edit as EditIcon,
    Add as AddIcon,
    Delete as DeleteIcon,
} from "@mui/icons-material";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { fetchGet, fetchPost, fetchPut } from "../../services/data-service";
import { Spinner } from "../../components";
import "../../styles/LabManagerTabs/Finances.css";

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY
);

const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: "14px",
            color: "#424770",
            letterSpacing: "0.025em",
            "::placeholder": {
                color: "#bbb",
            },
            fontFamily: "poppins, Helvetica, Arial, sans-serif",
        },
        invalid: {
            color: "red",
        },
    },
};

const CreditCardForm = ({ selectedCard }) => {
    const {
        reset,
        register,
        formState: { errors },
    } = useFormContext();

    useEffect(() => {
        if (selectedCard) {
            reset({
                cardName: selectedCard.billing_details?.name || "",
                cardNumber: selectedCard.card?.last4 || "",
                expiry: selectedCard.card?.exp_month || "",
                securityCode: selectedCard.card?.cvc || "",
                defaultPaymentMethod: selectedCard.isDefault || false,
                address: selectedCard.billing_details?.address?.line1 || "",
                apt: selectedCard.billing_details?.address?.line2 || "",
                postalCode:
                    selectedCard.billing_details?.address?.postal_code || "",
                country: selectedCard.billing_details?.address?.country || "",
                province: selectedCard.billing_details?.address?.state || "",
                city: selectedCard.billing_details?.address?.city || "",
            });
        } else {
            reset({
                cardName: "",
                cardNumber: "",
                expiry: "",
                securityCode: "",
                defaultPaymentMethod: false,
            });
        }
    }, [selectedCard]);

    return (
        <form className="lab-manager-finances-form-content">
            <h2 className="lab-manager-finances-form-header">
                Billing Address
            </h2>
            <div className="lab-manager-finances-form-row">
                <div
                    style={{ flex: 2 }}
                    className="lab-manager-finances-form-group"
                >
                    <label htmlFor="streetAddress">
                        Street address <span className="required">*</span>
                    </label>
                    <input
                        type="text"
                        id="streetAddress"
                        required
                        placeholder="123 Main St"
                        className={`lab-manager-finances-input ${
                            errors.address ? "input-error" : ""
                        }`}
                        {...register("address", { required: true })}
                    />
                </div>
                <div className="lab-manager-finances-form-group">
                    <label htmlFor="aptSuiteUnit">Apt / Unit / Room</label>
                    <input
                        type="text"
                        id="aptSuiteUnit"
                        placeholder="Apt 101"
                        className="lab-manager-finances-input"
                        {...register("apt")}
                    />
                </div>
                <div className="lab-manager-finances-form-group">
                    <label htmlFor="postalCode">
                        Postal Code <span className="required">*</span>
                    </label>
                    <input
                        type="text"
                        id="postCode"
                        required
                        placeholder="A1A 1A1"
                        className={`lab-manager-finances-input ${
                            errors.postalCode ? "input-error" : ""
                        }`}
                        {...register("postalCode", { required: true })}
                    />
                </div>
            </div>
            <div className="lab-manager-finances-form-group">
                <label htmlFor="country">
                    Country <span className="required">*</span>
                </label>
                <select
                    id="country"
                    required
                    className={`lab-manager-finances-input ${
                        errors.country ? "input-error" : ""
                    }`}
                    {...register("country", { required: true })}
                >
                    <option value="CA">Canada</option>
                </select>
            </div>
            <div className="lab-manager-finances-form-row">
                <div className="lab-manager-finances-form-group">
                    <label htmlFor="province">
                        Province <span className="required">*</span>
                    </label>
                    <select
                        id="province"
                        required
                        className={`lab-manager-finances-input ${
                            errors.province ? "input-error" : ""
                        }`}
                        {...register("province", { required: true })}
                    >
                        <option value="">Select a province</option>
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NU">Nunavut</option>
                        <option value="YT">Yukon</option>
                    </select>
                </div>
                <div className="lab-manager-finances-form-group">
                    <label htmlFor="city">
                        City <span className="required">*</span>
                    </label>
                    <input
                        type="text"
                        id="city"
                        required
                        placeholder="Toronto"
                        className={`lab-manager-finances-input ${
                            errors.city ? "input-error" : ""
                        }`}
                        {...register("city", { required: true })}
                    />
                </div>
            </div>
            <h2 className="lab-manager-finances-form-header">
                Credit Card Details
            </h2>
            <div className="lab-manager-finances-form-group">
                <label htmlFor="cardName">
                    Name on card <span className="required">*</span>
                </label>
                <input
                    type="text"
                    id="cardName"
                    name="cardName"
                    placeholder="John Doe"
                    {...register("cardName", { required: true })}
                />
            </div>
            {!selectedCard && (
                <div className="lab-manager-finances-form-group">
                    <label htmlFor="cardNumber">
                        Card number <span className="required">*</span>
                    </label>
                    <CardNumberElement options={ELEMENT_OPTIONS} />
                </div>
            )}
            <div className="lab-manager-finances-form-row">
                <div className="lab-manager-finances-form-group">
                    <label>
                        Expiration date{" "}
                        {selectedCard && (
                            <span className="lab-manager-finances-form-group-note">
                                (Current: {selectedCard.card.exp_month}/
                                {selectedCard.card.exp_year})
                            </span>
                        )}
                        <span className="required">*</span>{" "}
                    </label>
                    <CardExpiryElement options={ELEMENT_OPTIONS} />
                </div>

                {!selectedCard && (
                    <div className="lab-manager-finances-form-group">
                        <label htmlFor="securityCode">
                            Security code <span className="required">*</span>
                        </label>
                        <CardCvcElement options={ELEMENT_OPTIONS} />
                    </div>
                )}
            </div>
            <div className="lab-manager-finances-form-group">
                <label htmlFor="defaultPaymentMethod">
                    <input
                        type="checkbox"
                        id="defaultPaymentMethod"
                        {...register("defaultPaymentMethod")}
                    />
                    Default Payment Method
                </label>
            </div>
        </form>
    );
};

const FinancesContainer = () => {
    const methods = useForm();
    const stripe = useStripe();
    const elements = useElements();

    const [activeTab, setActiveTab] = useState("billing");
    const [isCCModalOpen, setIsCCModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [currency, setCurrency] = useState("CAD");
    const [loading, setLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const customerId = "cus_Rg7ch1fp3TpOHw";

    useEffect(() => {
        const fetchCustomer = async () => {
            const customer = await fetchGet(
                `/stripe/customer/${customerId}`,
                false
            );

            const paymentMethods = await fetchGet(
                `/stripe/customer/${customerId}/payment-method`,
                false
            );

            paymentMethods.forEach((paymentMethod) => {
                paymentMethod.isDefault =
                    paymentMethod.id ===
                    customer.invoice_settings.default_payment_method;
            });

            setPaymentMethods(paymentMethods);
        };
        fetchCustomer();
    }, []);

    const updateCardDetails = async () => {
        setLoading(true);
        if (!(await methods.trigger())) {
            console.error(methods.formState.errors);
            return;
        }
        if (!elements || !stripe) {
            console.error("Stripe elements not initialized");
            return;
        }
        if (!(await methods.trigger())) {
            console.error(methods.formState.errors);
            return;
        }
        const card = elements.getElement(CardNumberElement);
        if (!card) {
            console.error("CardNumberElement not found");
            return;
        }

        await fetchPut(
            `/stripe/customer/${customerId}/payment-method/${selectedCard.id}`,
            {
                defaultPaymentMethod: methods.getValues("defaultPaymentMethod"),
                name: methods.getValues("cardName"),
                address: {
                    city: methods.getValues("city"),
                    country: methods.getValues("country"),
                    line1: methods.getValues("address"),
                    postal_code: methods.getValues("postalCode"),
                    state: methods.getValues("province"),
                },
            },
            false
        );
    };

    const submitCardDetails = async () => {
        setLoading(true);
        if (!elements || !stripe) {
            console.error("Stripe elements not initialized");
            return;
        }
        if (!(await methods.trigger())) {
            console.error(methods.formState.errors);
            return;
        }
        const card = elements.getElement(CardNumberElement);
        if (!card) {
            console.error("CardNumberElement not found");
            return;
        }
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card,
            billing_details: {
                address: {
                    city: methods.getValues("city"),
                    country: methods.getValues("country"),
                    line1: methods.getValues("address"),
                    postal_code: methods.getValues("postalCode"),
                    state: methods.getValues("province"),
                },
                name: methods.getValues("cardName"),
            },
        });
        if (error) {
            console.error("Error creating Payment Method:", error);
        } else {
            console.log(
                "paymentlink",
                `/stripe/customer/${customerId}/payment-method/${
                    paymentMethod.id
                }?default=${methods.getValues("defaultPaymentMethod")}`
            );
            const res = await fetchPost(
                `/stripe/customer/${customerId}/payment-method/${
                    paymentMethod.id
                }?defaultPaymentMethod=${methods.getValues(
                    "defaultPaymentMethod"
                )}`,
                {},
                false
            );
        }
        window.location.reload();
    };

    return (
        <div className="lab-manager-finances-container">
            <div className="lab-manager-finances-tabs">
                <button
                    className={`lab-manager-finances-tab ${
                        activeTab === "billing" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("billing")}
                >
                    Billing
                </button>
                <button
                    className={`lab-manager-finances-tab ${
                        activeTab === "payment" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("payment")}
                >
                    Payment
                </button>
            </div>

            <div className="lab-manager-finances-tab-content">
                {activeTab === "billing" && (
                    <div className="lab-manager-finances-card">
                        <div className="lab-manager-finances-card-header">
                            <h2>Your billing cards</h2>
                            <p className="lab-manager-finances-description">
                                Only valid credit, debit cards, and reloadable
                                prepaid cards can be selected as your default
                                payment method on file, but you may add other
                                types of cards to pay your bill.
                            </p>
                        </div>

                        <div className="lab-manager-finances-card-content">
                            <div className="lab-manager-finances-radio-group">
                                {paymentMethods.map((paymentMethod) => (
                                    <label
                                        key={paymentMethod.id}
                                        className="lab-manager-finances-card-item"
                                        onClick={() => {
                                            setSelectedCard(paymentMethod);
                                            setIsCCModalOpen(true);
                                        }}
                                    >
                                        <div className="lab-manager-finances-card-details">
                                            <div className="lab-manager-finances-card-main">
                                                <CreditCardIcon className="lab-manager-finances-card-icon" />
                                                <span className="lab-manager-finances-card-number">
                                                    •••• •••• ••••{" "}
                                                    {paymentMethod.card.last4}
                                                </span>
                                                {paymentMethod.isDefault && (
                                                    <span className="lab-manager-finances-default-badge">
                                                        Default
                                                    </span>
                                                )}
                                            </div>
                                            <div className="lab-manager-finances-card-info">
                                                Expires{" "}
                                                {paymentMethod.card.exp_month}/
                                                {paymentMethod.card.exp_year} •{" "}
                                                {
                                                    paymentMethod
                                                        .billing_details.name
                                                }
                                            </div>
                                        </div>
                                        <button className="lab-manager-finances-edit-button">
                                            <DeleteIcon size={16} />
                                        </button>
                                    </label>
                                ))}
                            </div>

                            <button
                                className="lab-manager-finances-add-card-button"
                                onClick={() => {
                                    setSelectedCard(null);
                                    setIsCCModalOpen(true);
                                }}
                            >
                                <AddIcon size={16} />
                                Add a new card
                            </button>
                        </div>
                    </div>
                )}

                {activeTab === "payment" && (
                    <div className="lab-manager-finances-card">
                        <div className="lab-manager-finances-card-header">
                            <h2>Payment History</h2>
                            <p className="lab-manager-finances-description">
                                View and manage your payment history
                            </p>
                        </div>
                        <div className="lab-manager-finances-card-content">
                            <p className="lab-manager-finances-no-data">
                                No payment history available.
                            </p>
                        </div>
                    </div>
                )}
            </div>

            {/* <ModalComponent
                title={""} // Remove the dynamic title
                isOpen={isCCModalOpen}
                toggle={() => setIsCCModalOpen(!isCCModalOpen)}
                submitText="Save Changes"
                onSubmit={() => console.log("Submit changes")} // Placeholder for submit function
            >
                <div
                    className="lab-manager-finances-modal-container"
                    style={{ border: "none" }} // Remove the red border
                >
                    <h2
                        className="lab-manager-finances-modal-header"
                        style={{ fontSize: "24px" }}
                    >
                        Update bank details
                    </h2>
                    <form>
                        <div className="lab-manager-finances-form-group">
                            <label>Full name on bank account</label>
                            <input
                                type="text"
                                value="PI Name"
                                readOnly
                                className="lab-manager-finances-input"
                            />
                        </div>
                        <div className="lab-manager-finances-form-group">
                            <label>Bank country & currency</label>
                            <select className="lab-manager-finances-select">
                                <option value="Canada">Canada</option>
                            </select>
                        </div>
                        <div
                            className="lab-manager-finances-form-group"
                            style={{ border: "none" }}
                        >
                            <Radio
                                checked={currency === "CAD"}
                                onChange={() => setCurrency("CAD")}
                                value="CAD"
                                name="currency"
                                inputProps={{ "aria-label": "CAD" }}
                                sx={{
                                    color: "var(--primary-color)",
                                    "&.Mui-checked": {
                                        color: "var(--primary-color)",
                                    },
                                }}
                            />
                            <label htmlFor="currencyCAD">
                                My bank account is in CAD
                            </label>
                            <Radio
                                checked={currency === "other"}
                                onChange={() => setCurrency("other")}
                                value="other"
                                name="currency"
                                inputProps={{ "aria-label": "Other" }}
                                sx={{
                                    color: "var(--primary-color)", // Use your CSS variable or a specific color
                                    "&.Mui-checked": {
                                        color: "var(--primary-color)",
                                    },
                                }}
                            />
                            <label htmlFor="currencyOther">
                                My bank account is a different currency
                            </label>
                        </div>
                        <div className="lab-manager-finances-form-group">
                            <label>Bank name</label>
                            <input
                                type="text"
                                required
                                className="lab-manager-finances-input"
                            />
                        </div>
                        <div className="lab-manager-finances-form-group">
                            <label>Account number</label>
                            <input
                                type="text"
                                required
                                className="lab-manager-finances-input"
                            />
                        </div>
                        <div className="lab-manager-finances-form-group">
                            <label>Institution number</label>
                            <input
                                type="text"
                                required
                                className="lab-manager-finances-input"
                            />
                        </div>
                        <div className="lab-manager-finances-form-group">
                            <label>Transit number</label>
                            <input
                                type="text"
                                required
                                className="lab-manager-finances-input"
                            />
                        </div>
                    </form>
                </div>
            </ModalComponent> */}
            <FormProvider {...methods}>
                <ModalComponent
                    title={
                        selectedCard
                            ? "Edit credit card details"
                            : "Enter credit card details"
                    }
                    isOpen={isCCModalOpen}
                    toggle={() => {
                        setIsCCModalOpen(!isCCModalOpen);
                    }}
                    onCancel={() => {
                        setIsCCModalOpen(false);
                    }}
                    onSubmit={selectedCard ? submitCardDetails : () => {}}
                    submitText={
                        loading ? (
                            <>
                                <Spinner />{" "}
                                {selectedCard ? "Save changes" : "Add card"}
                            </>
                        ) : selectedCard ? (
                            "Save changes"
                        ) : (
                            "Add card"
                        )
                    }
                >
                    <CreditCardForm selectedCard={selectedCard} />
                </ModalComponent>
            </FormProvider>
        </div>
    );
};

const Finances = () => {
    return (
        <Elements stripe={stripePromise}>
            <FinancesContainer />
        </Elements>
    );
};

export default Finances;
