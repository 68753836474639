import React, { useRef, useState, useEffect, memo } from "react";
import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";
import { ErrorMessages } from "../../helpers/ListingHelpers";
import { Link as LinkIcon, Close as CloseIcon } from "@mui/icons-material";
import { licensingTypes } from "../../constants/ListingConstants";
import imagePlaceholder from "../../assets/image-placeholder.png";
import { ListingField, ModalComponent } from "../../components";
import "../../styles/CreateListingStyles.css";

const DigitalGood = memo(({ labMembers, listing }) => {
    const {
        register,
        control,
        setValue,
        getValues,
        formState: { errors },
        watch,
    } = useFormContext();

    const [linkModalOpen, setLinkModalOpen] = useState(false);
    const [linkDetails, setLinkDetails] = useState({ text: "", url: "" });
    const [selectionRange, setSelectionRange] = useState(null);
    const pageRef = useRef({});

    useEffect(() => {
        if (!listing) {
            setValue(
                "fields.price",
                {
                    value: 0,
                    units: null,
                    customUnits: null,
                },
                { shouldDirty: false }
            );
        }
    }, [setValue, listing]);

    const handleAddLink = () => {
        const textarea = document.querySelector(".retrieval-methods-textarea");
        if (textarea) {
            const selectedText = textarea.value.substring(
                textarea.selectionStart,
                textarea.selectionEnd
            );

            if (selectedText) {
                setSelectionRange({
                    start: textarea.selectionStart,
                    end: textarea.selectionEnd,
                });
                setLinkDetails({ text: selectedText, url: "" });
                setLinkModalOpen(true);
            } else {
                alert("Please select some text to add a link");
            }
        }
    };

    const handleLinkSubmit = () => {
        if (linkDetails.url) {
            const linkMarkdown = `[${linkDetails.text}](${linkDetails.url})`;
            const content = getValues("fields.retrievalMethods") || "";

            const newContent =
                content.substring(0, selectionRange.start) +
                linkMarkdown +
                content.substring(selectionRange.end);

            setValue("fields.retrievalMethods", newContent, {
                shouldDirty: true,
            });
        }
        setLinkModalOpen(false);
    };

    const handleImageChange = (file, index) => {
        /*** The value of the image changed before the image is uploaded.*/
        const currentImages = getValues("fields.imgs");
        const updatedImages = [...currentImages];

        const { ref, ...rest } = updatedImages[index];
        if (updatedImages[index].ref) {
            updatedImages[index] = {
                ...rest,
                url: URL.createObjectURL(file),
                file,
                shouldDelete: true,
            };
        } else {
            updatedImages[index] = {
                ...rest,
                url: URL.createObjectURL(file),
                file,
            };
        }
        console.log(updatedImages);
        setValue("fields.imgs", updatedImages, { shouldDirty: true });
    };

    const handleImageDelete = (index) => {
        const currentImages = getValues("fields.imgs");
        const updatedImages = [...currentImages];
        if (updatedImages[index].ref) {
            updatedImages[index] = {
                shouldDelete: true,
            };
        } else {
            updatedImages[index] = {};
        }
        setValue("fields.imgs", updatedImages, { shouldDirty: true });
    };

    const handlePasteButton = (index) => {
        navigator.clipboard
            .read()
            .then((clipboardItems) => {
                for (const clipboardItem of clipboardItems) {
                    for (const type of clipboardItem.types) {
                        if (type.startsWith("image/")) {
                            clipboardItem.getType(type).then((blob) => {
                                handleImageChange(blob, index);
                            });
                            return;
                        }
                    }
                }
            })
            .catch((err) => {
                console.error("Failed to read clipboard:", err);
            });
    };

    return (
        <>
            <h1 className="create-listing-header">Digital Good</h1>
            <div className="create-listing-container">
                <div className="create-listing-content">
                    <div ref={pageRef.about} className="create-listing-section">
                        <h2 className="create-listing-subheader">
                            Digital Good Overview
                        </h2>
                        <ListingField
                            field="title"
                            title="Digital Good Name"
                            description="What is the name of your digital good?"
                            required={true}
                        />
                        <ListingField
                            field="description"
                            title="Digital Good Description"
                            description="Describe your digital good in details."
                            required={true}
                            multiline={true}
                        />
                        <div className="create-listing-field">
                            <h3>
                                Lab Member
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                Who is the point of contact for this digital
                                good?
                            </h4>
                            <Controller
                                name="fields.labMember"
                                control={control}
                                rules={{ required: "Lab member is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={labMembers}
                                        className="labmember-select"
                                        placeholder="Select lab member"
                                    />
                                )}
                            />
                            {errors.fields?.labMember && (
                                <ErrorMessages
                                    message={errors.fields.labMember.message}
                                />
                            )}
                        </div>

                        <div className="create-listing-field">
                            <h3>
                                Licensing Type
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                What type of license applies to this digital
                                good?
                            </h4>
                            <Controller
                                name="fields.licensingType"
                                control={control}
                                rules={{
                                    required: "Licensing type is required",
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={licensingTypes}
                                        className="licensing-select"
                                        placeholder="Select licensing type"
                                    />
                                )}
                            />
                            {errors.fields?.licensingType && (
                                <ErrorMessages
                                    message={
                                        errors.fields.licensingType.message
                                    }
                                />
                            )}
                        </div>

                        <div className="create-listing-field">
                            <h3>
                                Access Method
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                How can users access this digital good? (e.g.,
                                GitHub repository, download link, API endpoint)
                            </h4>
                            <div className="textarea-container">
                                <textarea
                                    className={`input retrieval-methods-textarea ${
                                        errors.fields?.retrievalMethods
                                            ? "input-error"
                                            : ""
                                    }`}
                                    placeholder="Enter access method and instructions..."
                                    {...register("fields.retrievalMethods", {
                                        required: "Access Method are required",
                                    })}
                                />
                                <button
                                    type="button"
                                    className="add-link-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleAddLink();
                                    }}
                                >
                                    <LinkIcon /> Add Link
                                </button>
                            </div>
                            {errors.fields?.retrievalMethods && (
                                <ErrorMessages
                                    message={
                                        errors.fields.retrievalMethods.message
                                    }
                                />
                            )}
                        </div>

                        <div className="create-listing-field">
                            <h3>Relevant Link</h3>
                            <h4>
                                Add a link to additional information about the
                                digital good (e.g., documentation, GitHub
                                repository)
                            </h4>
                            <Controller
                                name="fields.relevantLink"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`input ${
                                            errors.fields?.relevantLink
                                                ? "input-error"
                                                : ""
                                        }`}
                                        type="url"
                                        placeholder="https://"
                                    />
                                )}
                            />
                            {errors.fields?.relevantLink && (
                                <ErrorMessages
                                    message={errors.fields.relevantLink.message}
                                />
                            )}
                        </div>

                        <div className="create-listing-field">
                            <h3>Collaboration</h3>
                            <h4>
                                Collaborative equipment will be shown in the
                                marketplace.
                            </h4>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="fields.isCollaborative"
                                        control={control}
                                        defaultValue={false}
                                        render={({
                                            field: { value, ...field },
                                        }) => (
                                            <Checkbox
                                                {...field}
                                                checked={value}
                                                style={{
                                                    color: "var(--primary-color)",
                                                }}
                                            />
                                        )}
                                    />
                                }
                                label="Allow collaboration"
                            />
                        </div>

                        <div className="create-listing-field">
                            <h3>Listing Images</h3>
                            <h4>
                                Add an image of the digital good. You can add up
                                to 3 images.
                            </h4>
                            <div className="image-input-container">
                                {watch("fields.imgs").map(({ url }, index) => (
                                    <label
                                        key={index}
                                        className="image-upload-label"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <input
                                            placeholder={imagePlaceholder}
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                handleImageChange(
                                                    e.target.files[0],
                                                    index
                                                );
                                            }}
                                            style={{ display: "none" }}
                                        />
                                        <div
                                            className="image-upload-square"
                                            style={{
                                                backgroundImage: `url(${
                                                    url || imagePlaceholder
                                                })`,
                                            }}
                                        >
                                            {!url && (
                                                <div className="image-upload-instructions">
                                                    <span>Click to upload</span>
                                                </div>
                                            )}
                                        </div>
                                        {index === 0 && (
                                            <span className="image-label">
                                                Primary
                                            </span>
                                        )}
                                        <div className="image-actions">
                                            {!url && (
                                                <button
                                                    type="button"
                                                    className="paste-image-button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handlePasteButton(
                                                            index
                                                        );
                                                    }}
                                                >
                                                    Paste Image
                                                </button>
                                            )}
                                            {url && (
                                                <button
                                                    className="delete-image"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleImageDelete(
                                                            index
                                                        );
                                                    }}
                                                >
                                                    <CloseIcon
                                                        style={{
                                                            fontSize: "16px",
                                                        }}
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalComponent
                title="Add Link"
                isOpen={linkModalOpen}
                toggle={() => setLinkModalOpen(false)}
                submitText="Insert Link"
                onSubmit={handleLinkSubmit}
            >
                <div className="link-modal-content">
                    <input
                        type="text"
                        placeholder="Link Text"
                        value={linkDetails.text}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                text: e.target.value,
                            })
                        }
                    />
                    <input
                        type="url"
                        placeholder="URL (https://...)"
                        value={linkDetails.url}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                url: e.target.value,
                            })
                        }
                    />
                </div>
            </ModalComponent>
        </>
    );
});

export default DigitalGood;
